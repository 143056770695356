import React, { useState } from "react";
import KSTKAnalyticsInput from "./KSTKAnalyticsInput";
import { KSTKAnalyticsButton } from "./KSTKAnalyticsButton";
import ReCAPTCHA from "react-google-recaptcha";

const KSTKAnalyticsLogin = ({
  authenticate,
  resetPassword,
  changePage,
  changeLanguage,
  translateText,
  getLanguage,
  getShowLoginCaptcha,
}) => {
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
  const [showLoginCaptcha, setShowLoginCaptcha] = useState(false);

  const updateLoginInfo = (e) => {
    const { name, value } = e.target;
    setLoginInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    authenticate(loginInfo);
    if (getShowLoginCaptcha()) {
      setShowLoginCaptcha(true);
    }
  };

  const recoverPassword = () => {
    setRecoverPasswordOpen(true);
  };

  const handleChangeLanguage = (e, targetElement, lang) => {
    changeLanguage(lang);
  };

  const [recoverInfo, setRecoverInfo] = useState({ resetEmail: "", captcha: null });
  const [recoverPasswordOpen, setRecoverPasswordOpen] = useState(false);

  const updateRecoverInfo = (e) => {
    const { name, value } = e.target;
    setRecoverInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  function onChangeLoginCaptcha(value) {
    setLoginInfo((prevState) => ({ ...prevState, ["captcha"]: value }));
  }

  const isLoginFormEmpty = () => {
    if (showLoginCaptcha && loginInfo.captcha == null) {
      return true;
    } else {
      return false;
    }

    /* setTimeout(() => {
      let loginFormEmailInput = document.getElementById("email");
      let loginFormPasswordInput = document.getElementById("password");
      if (!loginFormEmailInput.matches(":autofill") || !loginFormPasswordInput.matches(":autofill")) {
        if (!showLoginCaptcha) {
          return true;
        } else {
          if (loginInfo.captcha != null) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }, 500); */
  };

  const isRecoverFormEmpty = () => {
    return recoverInfo.resetEmail == "" || recoverInfo.captcha == null;
  };

  const handleResetPassword = () => {
    setRecoverPasswordOpen(false);
    return resetPassword(recoverInfo);
  };

  const closeRecoverPassword = () => {
    setRecoverPasswordOpen(false);
  };

  function onChange(value) {
    setRecoverInfo((prevState) => ({ ...prevState, ["captcha"]: value }));
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* Login */}
      <div className="kstkAnalyticsLoginWrapper">
        <div className="kstkAnalyticsLoginFormWrapper">
          <div className="kstkAnalyticsLogo"></div>
          <div className="kstkAnalyticsLoginHeading">
            <h2>Predictive Analytics</h2>
          </div>
          <div className="kstkAnalyticsLoginFormCard">
            <h3>Login</h3>
            {/* <div>Para aceder à plataforma insira o seu e-mail e password</div> */}
            <div className="kstkAnalyticsLoginForm">
              <form onSubmit={handleLogin} id="loginForm">
                <KSTKAnalyticsInput
                  id="email"
                  label="E-mail"
                  type="email"
                  name="email"
                  value={loginInfo.email}
                  onInputChange={updateLoginInfo}
                />
                <KSTKAnalyticsInput
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  value={loginInfo.password}
                  onInputChange={updateLoginInfo}
                />
                {showLoginCaptcha ? (
                  <ReCAPTCHA
                    className="kstkAnalyticsLoginFormCaptcha"
                    sitekey="6Ld0CEgqAAAAAJcPavdtmDO604W0K4UHRZNeCIsz"
                    name="captcha"
                    onChange={onChangeLoginCaptcha}
                  />
                ) : null}
              </form>
            </div>
            <div className="kstkAnalyticsLoginFormButtons">
              <KSTKAnalyticsButton
                action="kstkSmallPrimaryButton"
                type="submit"
                form="loginForm"
                text={translateText("login")}
                disabledFunction={isLoginFormEmpty}
                clickFunction={null}
              />
              <KSTKAnalyticsButton
                action="kstkSmallTertiaryButton"
                type="button"
                text={translateText("forgotPassword")}
                clickFunction={recoverPassword}
              />
              <div className="kstkAnalyticsLanguageButtons">
                <KSTKAnalyticsButton
                  action="kstkSmallTertiaryButton"
                  type="button"
                  text="PT"
                  clickFunction={handleChangeLanguage}
                  clickFunctionArgs="pt-PT"
                />
                <KSTKAnalyticsButton
                  action="kstkSmallTertiaryButton"
                  type="button"
                  text="EN"
                  clickFunction={handleChangeLanguage}
                  clickFunctionArgs="en-GB"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="kstkAnalyticsLoginBackground"
          style={{
            backgroundImage:
              getLanguage() == null || getLanguage() == "pt-PT"
                ? "url('../../images/login_background.svg')"
                : "url('../../images/login_background_en.svg')",
          }}
        ></div>
      </div>
      {/* Recover Password */}
      {recoverPasswordOpen ? (
        <div className="kstkAnalyticsRecoverPasswordWrapper">
          <div className="kstkAnalyticsRecoverPasswordModalWrapper">
            <div className="kstkAnalyticsRecoverPasswordFormWrapper">
              <div className="kstkAnalyticsRecoverPasswordClose">
                <KSTKAnalyticsButton
                  type="button"
                  title="Fechar recuperação de password"
                  action="kstkMediumTertiaryButton"
                  icon="close-outline"
                  clickFunction={closeRecoverPassword}
                />
              </div>
              <div className="kstkAnalyticsRecoverPasswordIcon">
                <ion-icon name="key-outline"></ion-icon>
              </div>
              <h1>{translateText("recoverPassword")}</h1>
              <div>{translateText("recoverPasswordText")}</div>
              <div className="kstkAnalyticsRecoverPasswordForm">
                <KSTKAnalyticsInput
                  label="E-mail"
                  type="email"
                  name="resetEmail"
                  value={recoverInfo.resetEmail}
                  onInputChange={updateRecoverInfo}
                />
                <ReCAPTCHA sitekey="6LceiR4UAAAAALNEzh5Pch8geCwO9ZzcwrQ-NR8W" onChange={onChange} />
              </div>
              <div className="kstkAnalyticsRecoverPasswordFormButtons">
                <KSTKAnalyticsButton
                  type="button"
                  action="kstkSmallPrimaryButton"
                  text={translateText("recoverPassword")}
                  disabledFunction={isRecoverFormEmpty}
                  clickFunction={handleResetPassword}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default KSTKAnalyticsLogin;
