var app = angular.module("analytics");

app.controller("LoginCtrl", [
  "$scope",
  "$http",
  "clientsFactory",
  "clientPreferencesFactory",
  "localizationFactory",
  "$state",
  function ($scope, $http, clientsFactory, clientPreferencesFactory, localizationFactory, $state) {
    if (clientsFactory.isAuthenticated()) {
      $state.go("home");
    }

    $scope.loggedUser = null;

    $scope.email = "";
    $scope.password = "";
    $scope.errorMessage = "";

    $scope.authenticate = function (loginInfo) {
      let email, password;
      if (loginInfo != null) {
        email = loginInfo.email;
        password = loginInfo.password;
      } else {
        email = $scope.email;
        password = $scope.password;
      }

      var o = clientsFactory
        .login(email, password)
        .then(function (data) {
          $scope.loggedUser = data;

          $scope.$parent.log({
            type: "login",
            details: "",
            date: new Date(),
          });

          $http.defaults.headers.common["Authorization"] = data.id;

          var lsAuth = {
            value: data,
            timestamp: new Date().getTime(),
            ttl: data.ttl,
          };
          setAuthentication(lsAuth);

          if ($scope.loggedUser.user.language != null) {
            setCookie("KSTKLang", $scope.loggedUser.user.language, 7);
          }

          clientPreferencesFactory.getByProperty("user", data.user.id).then(function (preferencesData) {
            if (preferencesData.length == 0) {
              let clientPref = {};
              clientPref.user = data.user.id;
              clientPreferencesFactory.create(clientPref).then(function (createdClientPref) {
                clientsFactory.loginCallback(data);
                $state.go("home");
              });
            } else {
              clientsFactory.loginCallback(data);
              $state.go("home");
            }
          });
        })
        .catch(function () {
          $scope.$parent.alertLoginFailed();
        });
    };

    $scope.resetEmail = "";
    $scope.captcha = null;

    $scope.resetPassword = function (recoverInfo) {
      clientsFactory
        .validate(recoverInfo.captcha)
        .then(function (data) {
          if (data.data.success) {
            clientsFactory
              .reset(recoverInfo.resetEmail)
              .then(() => {
                alert("Foi-lhe enviado um email com a sua nova password");
                $state.go("login");
              })
              .catch(() => alert("Ocorreu um erro ao tentar recuperar a sua password"));
          } else {
            alert("Erro na validação do Captcha");
          }
        })
        .catch(function () {
          alert("Erro na validação do Captcha");
        });
    };

    /* $scope.resetPassword = function () {
      clientsFactory
        .reset($scope.email)
        .then(() => alert("Foi-lhe enviado um email com a sua nova password"))
        .catch(() => alert("Ocorreu um erro ao tentar recuperar a sua password"));
    }; */
  },
]);
