var app = angular.module("analytics");

app.controller("ClientCtrl", [
  "$scope",
  "clientsFactory",
  "localizationFactory",
  "$mdDialog",
  "$state",
  function ($scope, clientsFactory, localizationFactory, $mdDialog, $state) {
    var self = this;

    $scope.$parent.setTitle($scope.translateText("settings"));

    // Translate text

    $scope.translateText = function (text) {
      return localizationFactory.translateText(text);
    };

    $scope.currentUser = clientsFactory.getUser().user;
    $scope.passwordRepeat;

    if ($scope.currentUser.hasToResetPassword) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title($scope.translateText("resetPasswordDialogHeader"))
          .textContent($scope.translateText("resetPasswordDialogDesc"))
          .ariaLabel($scope.translateText("resetPasswordDialogDesc"))
          .ok("Ok")
      );
    }

    $scope.updateUser = function updateUser(userInfo) {
      if (userInfo.password != null && userInfo.password != "" && userInfo.password === userInfo.passwordRepeat) {
        if ($scope.currentUser.hasToResetPassword) {
          $scope.currentUser.hasToResetPassword = false;
          if ($scope.currentUser.tempPassword) {
            $scope.currentUser.tempPassword = null;
          }
        }
        $scope.currentUser.password = userInfo.password;
      } else if (userInfo.password != "" && userInfo.password !== userInfo.passwordRepeat) {
        alert("Passwords diferentes");
      }

      if ($scope.currentUser.name != userInfo.clientName || $scope.currentUser.surname != userInfo.clientSurname) {
        $scope.currentUser.name = userInfo.clientName;
        $scope.currentUser.surname = userInfo.clientSurname;
      }
      clientsFactory.modify($scope.currentUser.id, $scope.currentUser).then(function () {
        clientsFactory.updateUsr($scope.currentUser);
        alert($scope.translateText("userUpdatedWithSuccess"));
        window.location = "/#!/home";
        window.location.reload();
      });
    };
  },
]);
