// import {
//   parsePropNameValueMap
// } from "./controllers/genericModule-Utils";

export var app = angular
  .module("analytics", [
    "ui.router",
    "ngMaterial",
    "ngMessages",
    "ngMdIcons",
    "ui.grid",
    "ui.grid.pagination",
    "ui.grid.selection",
    "vcRecaptcha",
    "ui.grid.exporter",
    "ui.grid.resizeColumns",
    "ui.grid.grouping",
    "ui.grid.autoResize",
    "ui.grid.edit",
    "ui.grid.cellNav",
    "ui.grid.pinning",
  ])
  .config(function ($mdThemingProvider) {
    $mdThemingProvider.definePalette("KSTK_Palette", {
      50: "494949",
      100: "494949",
      200: "494949",
      300: "494949",
      400: "494949",
      500: "494949",
      600: "494949",
      700: "494949",
      800: "494949",
      900: "494949",
      A100: "494949",
      A200: "494949",
      A400: "494949",
      A700: "494949",
      contrastDefaultColor: "light", // whether, by default, text (contrast)
      // on this palette should be dark or light

      contrastDarkColors: [
        "50",
        "100", //hues which contrast should be 'dark' by default
        "200",
        "300",
        "400",
        "A100",
      ],
      contrastLightColors: undefined, // could also specify this if default was 'dark'
    });

    /* $mdThemingProvider.theme("default").primaryPalette("KSTK_Palette").accentPalette("KSTK_Palette"); */
  })
  .config(function ($mdThemingProvider) {
    $mdThemingProvider.definePalette("KSTK_Blue_Palette", {
      50: "0d5fd9",
      100: "0d5fd9",
      200: "0d5fd9",
      300: "0d5fd9",
      400: "0d5fd9",
      500: "0d5fd9",
      600: "0d5fd9",
      700: "0d5fd9",
      800: "0d5fd9",
      900: "0d5fd9",
      A100: "0d5fd9",
      A200: "0d5fd9",
      A400: "0d5fd9",
      A700: "0d5fd9",
      contrastDefaultColor: "light", // whether, by default, text (contrast)
      // on this palette should be dark or light

      contrastDarkColors: [
        "50",
        "100", //hues which contrast should be 'dark' by default
        "200",
        "300",
        "400",
        "A100",
      ],
      contrastLightColors: undefined, // could also specify this if default was 'dark'
    });

    $mdThemingProvider.theme("default").primaryPalette("KSTK_Blue_Palette").accentPalette("KSTK_Blue_Palette");
  })
  .config(function ($mdInkRippleProvider) {
    $mdInkRippleProvider.disableInkRipple();
  })
  .config(function ($mdDateLocaleProvider) {
    $mdDateLocaleProvider.months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    $mdDateLocaleProvider.shortMonths = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    $mdDateLocaleProvider.days = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    $mdDateLocaleProvider.shortDays = ["D", "S", "T", "Q", "Q", "S", "S"];
  })
  .config(function ($mdThemingProvider) {
    $mdThemingProvider.theme("fail-toast");
  });

function createCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

var generateFactory = function (options) {
  var application = options.application;
  var factoryName = options.factoryName;
  var routeName = options.routeName;
  var methods = options.methods;
  var properties = options.properties;

  application.factory(factoryName, [
    "$http",
    function ($http) {
      var o = {
        data: [],
        $http: $http,
        route: routeName,
      };

      for (var prop in properties) {
        o[prop] = properties[prop];
      }

      o.get = function (id) {
        return this.$http.get("/" + o.route + "/" + id).then(function (response) {
          return response.data;
        });
      };

      o.getAll = function () {
        return this.$http.get("/" + o.route).then(function (data) {
          angular.copy(data.data, o.data);

          return data.data;
        });
      };

      o.getAllList = function (list, org) {
        var result = [];
        for (let i = 0; i < list.length; i++) {
          result.push(
            this.$http.get("/" + "api/" + list[i] + "?filter[where][organization][like]=" + org).then(function (data) {
              angular.copy(data.data, o.data);

              return data.data;
            })
          );
        }
        return result;
      };

      o.getByProperty = function (propName, propValue, paginationSize, paginationStartIndex) {
        var self = this;

        if (
          paginationSize != null &&
          !isNaN(Number(paginationSize)) &&
          paginationStartIndex != null &&
          !isNaN(Number(paginationStartIndex))
        ) {
          return this.$http
            .get(
              "/" +
                o.route +
                "?filter[where][" +
                propName +
                "][like]=" +
                propValue +
                "&filter[limit]=" +
                paginationSize +
                "&filter[skip]=" +
                paginationStartIndex
            )
            .then(function (data) {
              return data.data;
            });
        } else {
          return this.$http
            .get("/" + o.route + "?filter[where][" + propName + "][like]=" + propValue)
            .then(function (data) {
              return data.data;
            });
        }
      };

      o.getByProperties = function (propNameValueMap, org, noOrgRequestFlag, paginationSize, paginationStartIndex) {
        var self = this;
        let query = "";

        if (org == "organization") {
          query = parsePropNameValueMap(propNameValueMap, org, true, paginationSize, paginationStartIndex);
        } else {
          query = parsePropNameValueMap(propNameValueMap, org, noOrgRequestFlag, paginationSize, paginationStartIndex);
        }

        if (query != "") {
          query = "filter=" + query;
          return this.$http.get("/" + o.route + "?" + query).then(function (data) {
            return data.data;
          });
        } else {
          if (
            paginationSize != null &&
            !isNaN(Number(paginationSize)) &&
            paginationStartIndex != null &&
            !isNaN(Number(paginationStartIndex))
          ) {
            return this.$http
              .get(
                "/" +
                  o.route +
                  "?filter[where][organization][like]=" +
                  org +
                  "&filter[limit]=" +
                  paginationSize +
                  "&filter[skip]=" +
                  paginationStartIndex
              )
              .then(function (data) {
                return data.data;
              });
          } else {
            return this.$http.get("/" + o.route + "?filter[where][organization][like]=" + org).then(function (data) {
              return data.data;
            });
          }
        }
      };

      o.getCustomEndpoint = function (org, endpoint) {
        var self = this;

        return this.$http.get("/api/Organizations/" + org + "/" + endpoint).then(function (response) {
          return response.data;
        });
      };

      o.create = function (pub) {
        return this.$http.post("/" + o.route, pub).then(function (data) {
          if (o.data != null && Array.isArray(o.data)) {
            o.data.push(data.data);
          }

          return data.data;
        });
      };

      o.modify = function (id, pub) {
        return this.$http.put("/" + o.route + "/" + id, pub).then(function (data) {
          data = data.data;

          for (var i = 0; i < o.data.length; i++) {
            if (o.data[i].id === data.id) {
              for (var prop in data) {
                o.data[i][prop] = data[prop];
              }
            }
          }

          return data;
        });
      };

      o.delete = function (id) {
        return this.$http.delete("/" + o.route + "/" + id).then(function (data) {
          data = data.data;

          for (var i = 0; i < o.data.length; i++) {
            if (o.data[i].id === id) {
              o.data.splice(i, 1);
            }
          }

          return data;
        });
      };

      o.update = function (pub) {
        return this.$http.post("/" + o.route, pub).then(function (data) {
          if (o.data != null && Array.isArray(o.data)) {
            o.data.push(data.data);
          }

          return data.data;
        });
      };

      o.setSelected = function (selectedCat) {
        o.selected = selectedCat;
      };

      o.getSelected = function () {
        return o.selected;
      };

      o.setParentScope = function (scp) {
        o.parentScope = scp;
      };

      o.getParentScope = function () {
        return o.parentScope;
      };

      o.getByOrganizationId = function (orgId) {
        var self = this;

        return self.$http.get("/" + o.route + "?filter[where][organization][like]=" + orgId).then(function (response) {
          return response.data;
        });
      };

      for (var method in methods) {
        o[method] = methods[method];
      }

      return o;
    },
  ]);
};

generateFactory({
  application: app,
  factoryName: "clientsFactory",
  routeName: "api/Clients",
  methods: {
    login: function (email, password) {
      var self = this;
      var EIGHT_HOURS = 60 * 60 * 8;

      return self.$http
        .post("/api/clients/login?include=user", {
          email: email,
          password: password,
          ttl: EIGHT_HOURS,
        })
        .then(function (data) {
          self.data = data.data;

          /* createCookie("userOrganization", data.data.user.organization); */

          return data.data;

          // self.get(data.userId).then(function (userData) {
          //     self.data.profile = userData.profile;
          // });
        });
    },
    logout: function () {
      var self = this;

      return self.$http.post("/api/clients/logout").then(function (data) {
        self.data = null;
        deleteAuthentication();
      });
    },
    reset: function (email) {
      var self = this;

      return self.$http.post("/api/clients/reset", {
        email: email,
      });
    },
    validate: function (captcha) {
      var self = this;

      return self.$http.post("/api/clients/validate", {
        captcha: captcha,
      });
    },
    welcomeNewClient: function (info) {
      var self = this;

      return self.$http.post("/api/clients/welcomeNewClient", {
        info: info,
      });
    },
    iptAssessmentConcludedAlert: function (info) {
      var self = this;

      return self.$http.post("/api/clients/iptAssessmentConcludedAlert", {
        info: info,
      });
    },
    isAuthenticated: function () {
      var self = this;

      if (!self.data || !self.data.id) {
        var lsAuth = getAuthentication();
        if (lsAuth != null) {
          if (new Date().getTime() < lsAuth.timestamp + lsAuth.ttl * 1000) {
            self.data = lsAuth.value; // if(http) { //   http.defaults.headers.common['Authorization'] = lsAuth.value.id; // }
            return true;
          } else {
            deleteAuthentication();
            return false;
          }
        }
      }

      return self.data ? self.data.id != null : false;
    },
    isAdmin: function () {
      return this.data && !Array.isArray(this.data) ? this.data.user.profile === "admin" : false;
    },
    isOrgAdmin: function () {
      return this.data && !Array.isArray(this.data) ? this.data.user.profile === "orgAdmin" : false;
    },
    isCPAdmin: function () {
      return this.data && !Array.isArray(this.data) ? this.data.user.profile === "cpAdmin" : false;
    },
    isVFXEduAdmin: function () {
      return this.data && !Array.isArray(this.data) ? this.data.user.profile === "vfxEduAdmin" : false;
    },
    getUser: function () {
      var self = this;

      var lsAuth = getAuthentication();
      if (lsAuth != null) {
        if (new Date().getTime() < lsAuth.timestamp + lsAuth.ttl * 1000) {
          return lsAuth.value;
        } else {
          if (self.data != null) {
            //window.alert("A sua sessão expirou. Será redirecionado para a página de login.");
            self.data = null;
            deleteAuthentication();
            window.location = "/#!/login";
            window.location.reload();
          }
        }
      }
      return self.data;
    },
    setUser: function (user) {
      this.data = user;
    },
    updateUsr: function (user) {
      var lsAuth = getAuthentication();
      if (lsAuth != null) {
        if (lsAuth.value != null && lsAuth.value.user != null) {
          lsAuth.value.user = user;
          setAuthentication(lsAuth);
        }
      }
    },
    getAll: function () {
      var self = this;

      return self.$http.get("/api/clients").then(function (data) {
        angular.copy(data.data, self.allData);

        return data.data;
      });
    },
  },
});

generateFactory({
  application: app,
  factoryName: "clientPreferencesFactory",
  routeName: "api/ClientPreferences",
});

generateFactory({
  application: app,
  factoryName: "productFactory",
  routeName: "api/Products",
  methods: {
    setSelectedProduct: function (selectedProduct) {
      var self = this;

      self.selectedProduct = selectedPro;
    },
    getSelectedProduct: function () {
      var self = this;

      return self.selectedProduct;
    },
    setProductScope: function (scp) {
      this.productScope = scp;
    },
    getProductScope: function () {
      return this.productScope;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "categoryFactory",
  routeName: "api/Categories",
  methods: {
    setSelectedCategory: function (selectedCat) {
      var self = this;

      self.selectedCategory = selectedCat;
    },
    getSelectedCategory: function () {
      var self = this;

      return self.selectedCategory;
    },
    setCategoryScope: function (scp) {
      this.categoryScope = scp;
    },
    getCategoryScope: function () {
      return this.categoryScope;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "formFactory",
  routeName: "api/SocialActions",
  methods: {
    setSelectedForm: function (selectedCat) {
      var self = this;

      self.selectedForm = selectedCat;
    },
    getSelectedForm: function () {
      var self = this;

      return self.selectedForm;
    },
    setFormScope: function (scp) {
      this.formScope = scp;
    },
    getFormScope: function () {
      return this.formScope;
    },
    checkNIF: function (input, data) {
      var flag = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i]["nif"] === input["nif"] && data[i]["year"] === input["year"]) {
          flag = flag + 1;
          if (flag > 1) {
            alert("NIF duplicado.");
            return true;
          }
        }
      }
      return false;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "organizationFactory",
  routeName: "api/Organizations",
  methods: {
    setSelected: function (selectedCat) {
      var self = this;

      self.selected = selectedCat;
    },
    getSelected: function () {
      var self = this;

      return self.selected;
    },
    setParentScope: function (scp) {
      this.parentScope = scp;
    },
    getParentScope: function () {
      return this.parentScope;
    },
    syncAAAF: function (orgId) {
      var self = this;

      return self.$http.get(`/api/Organizations/${orgId}/syncAAAF`);
    },
    syncASE: function (orgId) {
      var self = this;

      return self.$http.get(`/api/Organizations/${orgId}/syncASE`);
    },
    syncProfessors: function (orgId) {
      var self = this;

      return self.$http.get(`/api/Organizations/${orgId}/syncProfessors`);
    },
    getClassPlanInfo: function (orgId) {
      var self = this;

      return self.$http.get(`/api/Organizations/${orgId}/getClassPlanInfo`);
    },
    syncClassPlanEvaluations: function (syncInfo) {
      var self = this;

      return self.$http.post("/api/Organizations/syncClassPlanEvaluations", {
        syncInfo: syncInfo,
      });
    },
    syncClassPlanProfCoursePlan: function (syncInfo) {
      var self = this;

      return self.$http.post("/api/Organizations/syncClassPlanProfCoursePlan", {
        syncInfo: syncInfo,
      });
    },
  },
});

generateFactory({
  application: app,
  factoryName: "reportFactory",
  routeName: "api/Reports",
  methods: {
    setSelectedReport: function (ctx) {
      self.selectedReport = ctx;
    },
    getSelectedReport: function () {
      return self.selectedReport;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "reportingReportFactory",
  routeName: "api/KSTKReportingReports",
  methods: {
    setSelectedReportingReport: function (ctx) {
      self.selectedReport = ctx;
    },
    getSelectedReportingReport: function () {
      return self.selectedReport;
    },
    getReportFilteredBy: function (reportId) {
      var self = this;

      return self.$http.post("/api/KSTKReportingReports/getReportFilteredBy", reportId);
    },
  },
});

generateFactory({
  application: app,
  factoryName: "moduleFactory",
  routeName: "api/Modules",
  methods: {
    setSelectedModule: function (ctx) {
      self.selectedModule = ctx;
    },
    getSelectedModule: function () {
      return self.selectedModule;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "smartContentsFactory",
  routeName: "api/KSTK_Smart_Contents",
  methods: {
    setSelectedSmartContent: function (selectedSmartContent) {
      var self = this;

      self.selectedSmartContent = selectedPro;
    },
    getSelectedSmartContent: function () {
      var self = this;

      return self.selectedSmartContent;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "permissionsFactory",
  routeName: "api/Permissions",
  methods: {
    getClientModulePermission: function (info) {
      var self = this;

      return self.$http.post("/api/permissions/getClientModulePermission", {
        info: info,
      });
    },
  },
});

generateFactory({
  application: app,
  factoryName: "schoolFactory",
  routeName: "api/Schools",
  methods: {
    setSelectedSchool: function (selectedSchool) {
      var self = this;

      self.selectedSchool = selectedSchool;
    },
    getSelectedSchool: function () {
      var self = this;

      return self.selectedSchool;
    },
    setSchoolScope: function (scp) {
      this.schoolScope = scp;
    },
    getSchoolScope: function () {
      return this.schoolScope;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "typeFactory",
  routeName: "api/Types",
  methods: {
    setSelectedType: function (selectedType) {
      var self = this;

      self.selectedType = selectedType;
    },
    getSelectedType: function () {
      var self = this;

      return self.selectedType;
    },
    setTypeScope: function (scp) {
      this.typeScope = scp;
    },
    getTypeScope: function () {
      return this.typeScope;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "clusterFactory",
  routeName: "api/Clusters",
  methods: {
    setSelectedCluster: function (selectedCluster) {
      var self = this;

      self.selectedCluster = selectedCluster;
    },
    getSelectedCluster: function () {
      var self = this;

      return self.selectedCluster;
    },
    setClusterScope: function (scp) {
      this.clusterScope = scp;
    },
    getClusterScope: function () {
      return this.clusterScope;
    },
  },
});

generateFactory({
  application: app,
  factoryName: "aaafFactory",
  routeName: "api/AAAFs",
  methods: {
    setSelectedAAAF: function (selectedAAAF) {
      var self = this;

      self.selectedAAAF = selectedAAAF;
    },
    getSelectedAAAF: function () {
      var self = this;

      return self.selectedAAAF;
    },
    setAAAFScope: function (scp) {
      this.aaafScope = scp;
    },
    getAAAFScope: function () {
      return this.aaafScope;
    },
    checkNIF: function (input, data) {
      var flag = 0;
      for (var i = 0; i < data.length; i++) {
        if (data[i]["nif"] === input["nif"] && data[i]["year"] === input["year"]) {
          flag = flag + 1;
          if (flag > 1) {
            alert("NIF duplicado.");
            return true;
          }
        }
      }
      return false;
    },
  },
});

app.factory("storageFactory", [
  "$http",
  function ($http) {
    var o = {
      data: [],
      $http: $http,
    };

    o.createContainer = function (id) {
      return this.$http.post("/api/containers", {
        name: id,
      });
    };

    o.deleteContainer = function (id) {
      return this.$http.delete("/api/Containers/" + id);
    };

    o.listContainers = function () {
      return this.$http.get("/api/Containers/");
    };

    o.getContainer = function (id) {
      return this.$http.get("/api/Containers/" + id);
    };

    o.listFilesInContainer = function (id) {
      return this.$http.get("/api/Containers/" + id + "/files");
    };

    o.uploadFile = function (containerId, files) {
      var self = this;

      o.getContainer(containerId)
        .then((container) => {
          uploadFilesHandler(containerId, files, self);
        })
        .catch((response) => {
          // Cointainer does not exist
          if (response.status === 404) {
            o.createContainer(containerId)
              .then((created) => {
                uploadFilesHandler(containerId, files, self);
              })
              .catch((response) => {
                // Container was created in the meantime
                if (response.status === 500) {
                  uploadFilesHandler(containerId, files, self);
                }
              });
          }
        });

      return true;
    };

    function uploadFilesHandler(containerId, files, ctx) {
      if (files != null) {
        if (!Array.isArray(files)) {
          files = [files];
        }
      }
      for (var i = 0; i < files.length; i++) {
        var fd = new FormData();

        fd.append("file", files[i]);
        ctx.$http
          .post("/api/Containers/" + containerId + "/upload", fd, {
            withCredentials: false,
            headers: {
              "content-Type": undefined,
            },
            transformRequest: angular.identity,
          })
          .then(function (data) {
            console.log(data);
          })
          .catch(function (data) {
            console.log(data);
          });
      }
    }

    o.uploadFiles = function (containerId, files) {
      var ctx = this;
      let uploadPromises = [];

      for (var i = 0; i < files.length; i++) {
        var fd = new FormData();
        fd.append("file", files[i]);
        uploadPromises.push(
          ctx.$http.post("/api/Containers/" + containerId + "/upload", fd, {
            withCredentials: false,
            headers: {
              "content-Type": undefined,
            },
            transformRequest: angular.identity,
          })
        );
      }

      return uploadPromises;
    };

    o.downloadFile = function (containerId, fileName) {
      this.$http.get("/api/containers/" + containerId + "/download/" + fileName);
    };

    o.deleteFile = function (containerId, fileName) {
      return this.$http.delete("/api/containers/" + containerId + "/files/" + fileName);
    };

    return o;
  },
]);

/* generateFactory({
  application: app,
  factoryName: 'transStudTransFactory',
  routeName: 'api/Transp_Stud_Transps',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Stud_Transps?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    },

  }
});

generateFactory({
  application: app,
  factoryName: 'transStudFactory',
  routeName: 'api/Transp_Studs',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Studs?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    },

  }
});

generateFactory({
  application: app,
  factoryName: 'transMunFactory',
  routeName: 'api/Transp_Muns',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Muns?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    },

  }
});

generateFactory({
  application: app,
  factoryName: 'transSchoFactory',
  routeName: 'api/Transp_Schos',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Schos?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    },

  }
});

generateFactory({
  application: app,
  factoryName: 'transDistrFactory',
  routeName: 'api/Transp_Distrs',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Distrs?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    },

  }
});

generateFactory({
  application: app,
  factoryName: 'transOriDestFactory',
  routeName: 'api/Transp_Ori_Dests',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Ori_Dests?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    }
  }
});

generateFactory({
  application: app,
  factoryName: 'transTicketFactory',
  routeName: 'api/Transp_Tickets',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Tickets?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    }
  }
});

generateFactory({
  application: app,
  factoryName: 'transTickDistFactory',
  routeName: 'api/Transp_Tick_Dists',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Tick_Dists?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    }
  }
});

generateFactory({
  application: app,
  factoryName: 'transTranFactory',
  routeName: 'api/Transp_Trans',
  methods: {
    setSelectedTransportation: function (selectedTransportation) {
      var self = this;

      self.selectedTransportation = selectedTransportation;
    },
    getSelectedTransportation: function () {
      var self = this;

      return self.selectedTransportation;
    },
    setTransportationScope: function (scp) {
      this.transportationScope = scp;
    },
    getTransportationScope: function () {
      return this.transportationScope;
    },
    getByOrganizationId: function (orgId) {
      var self = this;

      return self.$http.get('/api/Transp_Trans?filter[where][organization][like]=' + orgId).then(function (data) {
        //angular.copy(data, self.data);
      });
    }
  }
}); */

generateFactory({
  application: app,
  factoryName: "logsFactory",
  routeName: "api/Logs",
  methods: {},
});

generateFactory({
  application: app,
  factoryName: "genericFactory",
  methods: {
    setRouteName: function (route) {
      this.route = "api/" + route;
    },
    checkDuplicate: function (input, original, data, fields) {
      let duplicateCount = 0;
      for (var i = 0; i < data.length; i++) {
        let isDuplicate;
        for (var j = 0; j < fields.length; j++) {
          if (input[fields[j]] == data[i][fields[j]]) {
            if (isDuplicate == undefined || isDuplicate == null) {
              isDuplicate = true;
            } else {
              isDuplicate = isDuplicate && true;
            }
          } else {
            isDuplicate = false;
          }
        }
        if (isDuplicate == true) {
          duplicateCount++;
        }
      }
      if (duplicateCount > 0) {
        if (duplicateCount == 1) {
          let isDuplicate;
          for (var j = 0; j < fields.length; j++) {
            if (input[fields[j]] == original[fields[j]]) {
              if (isDuplicate == undefined || isDuplicate == null) {
                isDuplicate = true;
              } else {
                isDuplicate = isDuplicate && true;
              }
            } else {
              isDuplicate = false;
            }
          }
          if (isDuplicate == true) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    ptSendCommunication: function (info) {
      var self = this;

      return self.$http.post("/api/class_plan_parent_communications/ptSendCommunication", {
        info: info,
      });
    },
    getFilteredData: function (
      primaryPropNameValueMap,
      secondaryPropNameValueMap,
      org,
      topFilterFields,
      getTopFilterDataFlag
    ) {
      var self = this;
      let request = {};
      if (Object.keys(primaryPropNameValueMap).length > 0) {
        let primaryQuery;
        primaryQuery = parsePropNameValueMap(primaryPropNameValueMap, org, null, null, null, true);
        request.primaryQuery = JSON.parse(primaryQuery);
      }
      if (Object.keys(secondaryPropNameValueMap).length > 0) {
        let secondaryQuery;
        secondaryQuery = parsePropNameValueMap(secondaryPropNameValueMap, org, null, null, null, true);
        request.secondaryQuery = JSON.parse(secondaryQuery);
      }
      request.filterFields = topFilterFields;
      if ((getTopFilterDataFlag != null) & (getTopFilterDataFlag == true)) {
        request.getTopFilterDataFlag = true;
      }
      return self.$http.post("/api/Class_Plan_School_Records/getFilteredData", {
        request: request,
      });
    },
    syncGenericModuleData: function (syncInfo) {
      var self = this;

      return self.$http.post("/api/Organizations/syncGenericModuleData", {
        syncInfo: syncInfo,
      });
    },
  },
});

generateFactory({
  application: app,
  factoryName: "metaModuleFactory",
  routeName: "api/Modules",
});

generateFactory({
  application: app,
  factoryName: "roleMappingFactory",
  routeName: "api/RoleMappings",
  methods: {
    getByProp: function (propName, propValue) {
      var self = this;

      return this.$http.get("/api/RoleMappings?filter[where][" + propName + "]=" + propValue).then(function (data) {
        return data.data;
      });
    },
  },
});

generateFactory({
  application: app,
  factoryName: "roleFactory",
  routeName: "api/Roles",
});

app.factory("httpAuthInterceptor", function ($q) {
  return {
    responseError: function (response) {
      // NOTE: detect error because of unauthenticated user
      if (response.status == 401 || response.status == 403) {
        if (
          response.data &&
          response.data.error &&
          response.data.error.message &&
          response.data.error.code == "LOGIN_FAILED"
        ) {
          return response;
        }
        //window.alert("A sua sessão expirou. Será redirecionado para a página de login.");
        // redirecting to login page
        deleteAuthentication();
        window.location = "/#!/login";
        window.location.reload();
        return response;
      } else {
        return $q.reject(response);
      }
    },
  };
});

app.factory("localizationFactory", [
  "$http",
  function ($http) {
    var o = {
      data: {
        "en-GB": {
          login: "Login",
          forgotPassword: "Forgot your password?",
          recoverPassword: "Recover Password",
          recoverPasswordText:
            "Please insert your e-mail. You will shortly receive an e-mail with a temporary password.",
          homepageHeader: "Hello",
          homepageRecentlyVisited: "Recently Visited",
          homepageRecentlyVisitedList: "Recently Visited List",
          homepageRecentlyVisitedListDesc:
            "The modules and reports that you recently visited will be displayed on this section.",
          homepageFavorites: "Favorites",
          homepageFavoritesList: "Favorites List",
          homepageFavoritesListDesc: "The modules and reports that you mark as favorite will appear on this section.",
          seeMore: "See more",
          navPermissions: "Permissions",
          navSettings: "Settings",
          logout: "Logout",
          privacyPolicy: "Privacy Policy",
          cookiePolicy: "Cookie Policy",
          termsAndConditions: "Terms and Conditions",
          footerCopyright: "All rights reserved",
          smartContentsGuides: "Guides",
          smartContentsNotifications: "Notifications",
          permissions: "Permissions",
          permissionsSentTempPassword: "Send temporary password to user",
          userProfile: "User",
          records: "Records",
          credentialsSentWithSuccess: "Credentials sent with success!",
          credentialsAlreadySentDialogHeader: "Credentials already sent",
          credentialsAlreadySentDialogDesc1: "Temporary credentials were already sent to the user on the",
          credentialsAlreadySentDialogDesc2: "Do you want to send again?",
          userHasNoInstitutionalEmailDialogDesc:
            "The selected user has no institutional e-mail. In order to send temporary credentials to the user, please edit the record.",
          name: "Name",
          surname: "Surname",
          profile: "Profile",
          hasLoggedIn: "Has logged in?",
          institutionalEmail: "Institutional e-mail",
          language: "Language",
          portuguese: "Portuguese",
          english: "English",
          organization: "Organization",
          reports: "Reports",
          modules: "Modules",
          settings: "Settings",
          newPassword: "New password",
          confirmPassword: "Confirm new password",
          thePasswordShouldContain: "The password should contain:",
          atLeastALowerCaseCharacter: "at least a lower case character",
          atLeastAnUpperCaseCharacter: "at least an upper case character",
          atLeastASpecialCharacter: "at least a special character (for example: !, ? or @)",
          atLeastANumber: "at least a number",
          between8And32Characters: "between 8 and 32 characters",
          differentPasswords: "Different passwords.",
          userUpdatedWithSuccess: "User updated with success!",
          new: "New",
          edit: "Edit",
          update: "Duplicate",
          delete: "Delete",
          view: "View",
          goUp: "Up",
          goDown: "Down",
          duplicate: "Duplicate",
          invalidSave: "Error while saving",
          invalidTabToSave:
            "Can't save records in this module tab. Please go to other tab that is not only for consultation.",
          duplicateRecord: "Duplicate record",
          all: "All",
          clearFilters: "Clear filters",
          mode: "Mode",
          save: "Save",
          closeWithoutSaving: "Close without saving",
          newField: "New field",
          emptyField: "(empty)",
          exportDocDialogHeader: "Export Document",
          exportDocDialogDesc1: "Do you want to export",
          exportDocDialogDesc2: "records?",
          entriesExportedDialogDesc: "records exported.",
          successfulSave: "Record successfully saved!",
          successfulDelete: "Record successfully deleted!",
          deleteRecordDialog: "Delete this record?",
          resetPasswordDialogHeader: "Reset Password",
          resetPasswordDialogDesc: "Due to security reasons, please reset your password.",
          createUser: "Create User",
          selectAll: "Select All",
          clearSelection: "Clear Selection",
          yes: "Yes",
          no: "No",
          selectSearch: "Search...",
          moduleDropdownSearch: "Search",
          gridColumnMenuOrderAsc: "Order ascending",
          gridColumnMenuOrderDesc: "Order descending",
          gridColumnMenuClearOrder: "Remove order",
          gridColumnMenuHideColumn: "Hide Column",
          gridColumnMenuGroup: "Group",
          gridColumnMenuAggCount: "Agr: Count",
          gridColumnMenuAggSum: "Agr: Sum",
          gridColumnMenuAggMin: "Agr: Min",
          gridColumnMenuAggMax: "Agr: Max",
          gridColumnMenuAggAvg: "Agr: Avg",
          gridMenuClearFilters: "Agr: Avg",
          gridMenuExportAllExcel: "Export all rows as Excel",
          gridMenuExportVisibleExcel: "Export visible rows as Excel",
          gridMenuExportAllPDF: "Export all rows as PDF",
          gridMenuExportVisiblePDF: "Export visible rows as PDF",
          gridMenuExportColumns: "Columns",
          policyDialogHeader: "Privacy Policy and Terms and Conditions Update",
          policyDialogText1: "I have read and I accept the",
          policyDialogText2: "and the",
          policyDialogText3: "of the KSTK Predictive Mindset Platform.",
          newAddedFavorite: "Favorite added!",
          Novo: "New",
          Atualização: "Update",
          Aviso: "Warning",
          welcomeNewClientEmailSubject: "KSTK Predictive Mindset - Temporary Access Credentials",
          welcomeNewClientEmailDesc1:
            ", welcome to the KSTK Predictive Mindset platform!</p><p>Your temporary password is: ",
          welcomeNewClientEmailDesc2:
            "</p><p>Please login at https://analytics.kstk.pt. For security reasons, the platform will require for you to create a new password.</p> <p>Let's get started!</p>",
        },
        "pt-PT": {
          login: "Iniciar sessão",
          forgotPassword: "Esqueceu-se da password?",
          recoverPassword: "Recuperar Password",
          recoverPasswordText:
            "Por favor insira o seu email. Irá receber brevemente uma mensagem com a sua nova password.",
          homepageHeader: "Olá",
          homepageRecentlyVisited: "Visitados recentemente",
          homepageRecentlyVisitedList: "Lista de Visitados Recentemente",
          homepageRecentlyVisitedListDesc: "Visualize o seguinte vídeo para saber como funciona esta secção.",
          homepageFavorites: "Favoritos",
          homepageFavoritesList: "Lista de Favoritos",
          homepageFavoritesListDesc:
            "Visualize o seguinte vídeo para saber como adicionar módulos ou relatórios a esta secção.",
          seeMore: "Ver mais",
          navPermissions: "Permissões",
          navSettings: "Definições",
          logout: "Sair",
          privacyPolicy: "Política de Privacidade",
          cookiePolicy: "Política de Cookies",
          termsAndConditions: "Termos e Condições",
          footerCopyright: "Todos os direitos reservados",
          smartContentsGuides: "Guias de Apoio",
          smartContentsNotifications: "Notificações",
          permissions: "Permissões",
          permissionsSentTempPassword: "Enviar credenciais temporárias a utilizador",
          userProfile: "Utilizador",
          records: "Registos",
          credentialsSentWithSuccess: "Credenciais enviadas com sucesso!",
          credentialsAlreadySentDialogHeader: "Credenciais já enviadas",
          credentialsAlreadySentDialogDesc1: "Foram enviadas credenciais temporárias a este utilizador no dia",
          credentialsAlreadySentDialogDesc2: "Deseja voltar a enviar?",
          userHasNoInstitutionalEmailDialogDesc:
            "O utilizador selecionado não tem e-mail institucional associado. Por favor edite o registo deste de modo a poder proceder ao envio das credenciais.",
          name: "Nome",
          surname: "Apelido",
          profile: "Perfil",
          hasLoggedIn: "Já acedeu?",
          institutionalEmail: "E-mail Institucional",
          language: "Língua",
          portuguese: "Português",
          english: "Inglês",
          organization: "Organização",
          reports: "Relatórios",
          modules: "Módulos",
          settings: "Definições",
          newPassword: "Nova password",
          confirmPassword: "Confirme a password",
          thePasswordShouldContain: "A password deve conter:",
          atLeastALowerCaseCharacter: "pelo menos uma letra minúscula",
          atLeastAnUpperCaseCharacter: "pelo menos uma letra maiúscula",
          atLeastASpecialCharacter: "pelo menos um caracter especial (por exemplo: !, ? ou @)",
          atLeastANumber: "pelo menos um número",
          between8And32Characters: "entre 8 e 32 caracteres",
          differentPasswords: "Passwords diferentes.",
          userUpdatedWithSuccess: "Utilizador atualizado com sucesso!",
          new: "Criar",
          edit: "Editar",
          update: "Renovar",
          delete: "Remover",
          view: "Ver",
          goUp: "Subir",
          goDown: "Descer",
          duplicate: "Duplicar",
          invalidSave: "Erro ao guardar",
          invalidTabToSave:
            "Não é possível guardar registos neste separador. Por favor navegue para outro separador que não seja apenas de consulta.",
          duplicateRecord: "Registo duplicado",
          all: "Todos",
          clearFilters: "Limpar filtros",
          mode: "Modo",
          save: "Guardar",
          closeWithoutSaving: "Fechar sem guardar",
          newField: "Adicionar campo",
          emptyField: "(vazio)",
          exportDocDialogHeader: "Exportação de Documento",
          exportDocDialogDesc1: "Deseja exportar",
          exportDocDialogDesc2: "registos?",
          entriesExportedDialogDesc: "registos exportados.",
          successfulSave: "Registo guardado com sucesso!",
          successfulDelete: "Registo eliminado com sucesso!",
          deleteRecordDialog: "Eliminar este registo?",
          resetPasswordDialogHeader: "Alteração de Password",
          resetPasswordDialogDesc: "Por questões de segurança deve alterar a sua password.",
          createUser: "Criar Utilizador",
          selectAll: "Selecionar Todos",
          clearSelection: "Limpar Seleção",
          yes: "Sim",
          no: "Não",
          selectSearch: "Pesquisar...",
          moduleDropdownSearch: "Pesquisar",
          gridColumnMenuOrderAsc: "Ordenar ascendente",
          gridColumnMenuOrderDesc: "Ordenar descendente",
          gridColumnMenuClearOrder: "Remover ordenação",
          gridColumnMenuHideColumn: "Esconder coluna",
          gridColumnMenuGroup: "Agrupar",
          gridColumnMenuAggCount: "Agr: Contar",
          gridColumnMenuAggSum: "Agr: Soma",
          gridColumnMenuAggMin: "Agr: Mínimo",
          gridColumnMenuAggMax: "Agr: Máximo",
          gridColumnMenuAggAvg: "Agr: Média",
          gridMenuExportAllExcel: "Exportar todos os dados como Excel",
          gridMenuExportVisibleExcel: "Exportar dados visíveis como Excel",
          gridMenuExportAllPDF: "Exportar todos os dados como PDF",
          gridMenuExportVisiblePDF: "Exportar dados visíveis como PDF",
          gridMenuExportColumns: "Colunas",
          policyDialogHeader: "Atualização de Política de Privacidade e de Termos e Condições de Utilização",
          policyDialogText1: "Li e aceito a",
          policyDialogText2: "e os",
          policyDialogText3: "da Plataforma KSTK Predictive Mindset.",
          newAddedFavorite: "Novo favorito adicionado!",
          Novo: "Novo",
          Atualização: "Atualização",
          Aviso: "Aviso",
          welcomeNewClientEmailSubject: "KSTK Predictive Mindset - Credenciais de Acesso à Plataforma",
          welcomeNewClientEmailDesc1:
            ", bem-vindo à Plaforma KSTK Predictive Mindset!</p><p>A sua password temporária é: ",
          welcomeNewClientEmailDesc2:
            "</p><p>Proceda ao início de sessão em https://analytics.kstk.pt. Por questões de segurança, a plataforma irá requisitar o registo de uma nova password pessoal.</p> <p>Boa navegação!</p>",
        },
        "es-ES": {
          new: "Nuevo",
          edit: "Editar",
          delete: "Borrar",
          categories: "Categorías",
          organizations: "Organizaciones",
          "report-management": "Gestión de Informes",
          permissions: "Permisos",
          logout: "Cerrar Sesión",
          name: "Nombre",
          description: "Descripción",
          "organizations.reports": "URL del Informe",
          "permissions.reports": "Acceso a Informes",
        },
        "ar-QA": {
          new: "الجديد",
          edit: "تصحيح",
          delete: "حذف",
          categories: "الاقسام",
          organizations: "المؤسسات",
          "report-management": "إدارة التقارير",
          permissions: "الأذونات",
          logout: "الخروج",
          name: "اسم",
          description: "وصف",
          "organizations.reports": "الإبلاغ عن عنوان ورل",
          "permissions.reports": "الوصول إلى التقارير",
        },
      },
      $http: $http,
      changeLanguage: changeLanguage,
      translateText: translateText,
      getLanguage: getLanguage,
    };

    function changeLanguage(lang, arg, footerLang) {
      if (footerLang) {
        setCookie("KSTKLang", footerLang, 7);
      } else {
        setCookie("KSTKLang", lang, 7);
      }
      window.location.reload();
    }

    function translateText(text) {
      let lang = getCookie("KSTKLang");
      if (lang == null) {
        lang = "pt-PT";
      }
      return this.data[lang][text];
    }

    function getLanguage() {
      let lang = getCookie("KSTKLang");
      if (lang != null) {
        return lang;
      }
      return null;
    }

    return o;
  },
]);

app.factory("modulesFactory", [
  "$http",
  function ($http) {
    var o = {
      data: [
        {
          name: "Tabela de Tipos",
          value: "gestao_tipos",
          destination: "genericModule/Types",
          icon: "list",
          admin: false,
          order: 0,
        },
        {
          name: "Tabela de Agrupamentos",
          value: "gestao_agrupamentos",
          destination: "genericModule/Clusters",
          icon: "list",
          admin: false,
          order: 1,
        },
        {
          name: "Tabela de Escolas",
          value: "gestao_escolas",
          destination: "genericModule/Schools",
          icon: "list",
          admin: false,
          order: 2,
        },
        {
          name: "Ação Social Escolar",
          value: "acao_social",
          destination: "genericModule/SocialActions",
          icon: "list",
          admin: false,
          order: 3,
        },
        {
          name: "AAAF",
          value: "aaaf",
          destination: "genericModule/AAAFs",
          icon: "list",
          admin: false,
          order: 4,
        },
        {
          name: "Tabela de Câmaras Municipais",
          value: "transp_mun",
          destination: "genericModule/Transp_Muns",
          icon: "list",
          admin: false,
          order: 5,
        },
        {
          name: "Tabela de Agrupamentos (CMS)",
          value: "transp_cms_cluster",
          destination: "genericModule/Transp_CMS_Clusters",
          icon: "list",
          admin: false,
          order: 6,
        },
        {
          name: "Tabela de Escolas (CMS)",
          value: "transp_cms_scho",
          destination: "genericModule/Transp_CMS_Schos",
          icon: "list",
          admin: false,
          order: 7,
        },
        {
          name: "Tabela de Escolas (Transportes)",
          value: "transp_scho",
          destination: "genericModule/Transp_Schos",
          icon: "list",
          admin: false,
          order: 8,
        },
        {
          name: "Tabela de Locais de Dist de Senhas",
          value: "transp_distr",
          destination: "genericModule/Transp_Distrs",
          icon: "list",
          admin: false,
          order: 9,
        },
        {
          name: "Tabela de Locais de Ori e Dest",
          value: "transp_ori_dest",
          destination: "genericModule/Transp_Ori_Dests",
          icon: "list",
          admin: false,
          order: 10,
        },
        {
          name: "Tabela de Senhas",
          value: "transp_ticket",
          destination: "genericModule/Transp_Tickets",
          icon: "list",
          admin: false,
          order: 11,
        },
        {
          name: "Tabela de Transportes",
          value: "transp_trans",
          destination: "genericModule/Transp_Trans",
          icon: "list",
          admin: false,
          order: 12,
        },
        {
          name: "Tabela de Anos de Escolaridade",
          value: "transp_scho_years",
          destination: "genericModule/Transp_Scho_Years",
          icon: "list",
          admin: false,
          order: 13,
        },
        {
          name: "Transportes (CMS)",
          value: "transp_cms_studs",
          destination: "genericModule/Transp_CMS_Studs",
          icon: "list",
          admin: false,
          order: 14,
        },
        {
          name: "Transportes - Carregamentos (CMS)",
          value: "transp_cms_charges",
          destination: "genericModule/Transp_CMS_Charges",
          icon: "list",
          admin: false,
          order: 15,
        },
        {
          name: "Transportes",
          value: "transp_studs",
          destination: "genericModule/Transp_Studs",
          icon: "list",
          admin: false,
          order: 16,
        },
        {
          name: "Tabela de Repartições",
          value: "ipt_distributions",
          destination: "genericModule/IPT_Distributions",
          icon: "list",
          admin: false,
          order: 17,
        },
        {
          name: "Tabela de Produtos",
          value: "ipt_products",
          destination: "genericModule/IPT_Products",
          icon: "list",
          admin: false,
          order: 18,
        },
        {
          name: "Tabela de Clientes",
          value: "ipt_clients",
          destination: "genericModule/IPT_Clients",
          icon: "list",
          admin: false,
          order: 19,
        },
        {
          name: "Tabela de Datacenters",
          value: "ipt_datacenters",
          destination: "genericModule/IPT_Datacenters",
          icon: "list",
          admin: false,
          order: 20,
        },
        {
          name: "Tabela de Gestores",
          value: "ipt_managers",
          destination: "genericModule/IPT_Managers",
          icon: "list",
          admin: false,
          order: 21,
        },
        {
          name: "Tabela de Segmentos",
          value: "ipt_segments",
          destination: "genericModule/IPT_Segments",
          icon: "list",
          admin: false,
          order: 22,
        },
        {
          name: "Rateio de Custos",
          value: "ipt_costs",
          destination: "genericModule/IPT_Costs",
          icon: "list",
          admin: false,
          order: 23,
        },
        {
          name: "Rateio de Orçamento de Custos",
          value: "ipt_budget_costs",
          destination: "genericModule/IPT_Budget_Costs",
          icon: "list",
          admin: false,
          order: 24,
        },
        {
          name: "Orçamento de Vendas",
          value: "ipt_sales",
          destination: "genericModule/IPT_Sales",
          icon: "list",
          admin: false,
          order: 25,
        },
        {
          name: "Tabela de Estudantes (Mun)",
          value: "getMunicipalETLStudents",
          destination: "genericModule/getMunicipalETLStudents",
          icon: "list",
          admin: false,
          order: 26,
        },
        {
          name: "Tabela de Estudantes",
          value: "getETLStudents",
          destination: "genericModule/getETLStudents",
          icon: "list",
          admin: false,
          order: 27,
        },
        {
          name: "Tabela de Avaliações",
          value: "getETLEvaluations",
          destination: "genericModule/getETLEvaluations",
          icon: "list",
          admin: false,
          order: 28,
        },
        {
          name: "Tabela de Absentismo",
          value: "getETLAbsenteeism",
          destination: "genericModule/getETLAbsenteeism",
          icon: "list",
          admin: false,
          order: 29,
        },
        {
          name: "Tabela de Matrículas",
          value: "getETLRegistrations",
          destination: "genericModule/getETLRegistrations",
          icon: "list",
          admin: false,
          order: 30,
        },
        {
          name: "Níveis de Ensino",
          value: "class_plan_education_level",
          destination: "genericModule/Class_Plan_Education_Levels",
          icon: "list",
          admin: false,
          order: 31,
        },
        {
          name: "Anos de Escolaridade",
          value: "class_plan_school_year",
          destination: "genericModule/Class_Plan_School_Years",
          icon: "list",
          admin: false,
          order: 32,
        },
        {
          name: "Tipo de Medidas de Suporte",
          value: "class_plan_measure_type",
          destination: "genericModule/Class_Plan_Measure_Types",
          icon: "list",
          admin: false,
          order: 33,
        },
        {
          name: "Medidas de Suporte",
          value: "class_plan_measure",
          destination: "genericModule/Class_Plan_Measures",
          icon: "list",
          admin: false,
          order: 34,
        },
        {
          name: "Critérios de Avaliação",
          value: "class_plan_eval_crit_domain",
          destination: "genericModule/Class_Plan_Evaluation_Crit_Domains",
          icon: "list",
          admin: false,
          order: 35,
        },
        {
          name: "Estratégias para a Turma",
          value: "class_plan_class_strategy",
          destination: "genericModule/Class_Plan_Class_Strats",
          icon: "list",
          admin: false,
          order: 36,
        },
        {
          name: "Pontos Fortes/Fracos da Turma",
          value: "class_plan_class_classification",
          destination: "genericModule/Class_Plan_Class_Classifications",
          icon: "list",
          admin: false,
          order: 37,
        },
        {
          name: "Áreas de Competência",
          value: "class_plan_expertise_field",
          destination: "genericModule/Class_Plan_Expertise_Fields",
          icon: "list",
          admin: false,
          order: 38,
        },
        {
          name: "Descritores de Áreas de Competência",
          value: "class_plan_expertise_field_desc",
          destination: "genericModule/Class_Plan_Expertise_Field_Descs",
          icon: "list",
          admin: false,
          order: 39,
        },
        {
          name: "Aprendizagens Essenciais",
          value: "class_plan_essential_learning",
          destination: "genericModule/Class_Plan_Essential_Learnings",
          icon: "list",
          admin: false,
          order: 40,
        },
        {
          name: "Tipos de Instrumentos de Avaliação",
          value: "class_plan_evaluation_instrument_type",
          destination: "genericModule/Class_Plan_Evaluation_Instrument_Types",
          icon: "list",
          admin: false,
          order: 41,
        },
        {
          name: "Níveis de Avaliação Final",
          value: "class_plan_final_evaluation_level",
          destination: "genericModule/Class_Plan_Final_Evaluation_Levels",
          icon: "list",
          admin: false,
          order: 42,
        },
        {
          name: "Disciplinas",
          value: "class_plan_subject",
          destination: "genericModule/Class_Plan_Subjects",
          icon: "list",
          admin: false,
          order: 43,
        },
        {
          name: "Tipos de Curso",
          value: "class_plan_course_type",
          destination: "genericModule/Class_Plan_Course_Types",
          icon: "list",
          admin: false,
          order: 44,
        },
        {
          name: "Curso",
          value: "class_plan_course",
          destination: "genericModule/Class_Plan_Courses",
          icon: "list",
          admin: false,
          order: 45,
        },
        {
          name: "Indisciplina Tipos de Ocorrência",
          value: "class_plan_indiscipline_occurrence_type",
          destination: "genericModule/Class_Plan_Indiscipline_Occurrence_Types",
          icon: "list",
          admin: false,
          order: 46,
        },
        {
          name: "Indisciplina Tipos de Medidas",
          value: "class_plan_indiscipline_measure_type",
          destination: "genericModule/Class_Plan_Indiscipline_Measure_Types",
          icon: "list",
          admin: false,
          order: 47,
        },
        {
          name: "Indisciplina Medidas",
          value: "class_plan_indiscipline_measure",
          destination: "genericModule/Class_Plan_Indiscipline_Measures",
          icon: "list",
          admin: false,
          order: 48,
        },
        {
          name: "Agrupamentos",
          value: "class_plan_cluster",
          destination: "genericModule/Class_Plan_Clusters",
          icon: "list",
          admin: false,
          order: 49,
        },
        {
          name: "Escolas",
          value: "class_plan_school",
          destination: "genericModule/Class_Plan_Schools",
          icon: "list",
          admin: false,
          order: 50,
        },
        {
          name: "Turmas",
          value: "class_plan_class",
          destination: "genericModule/Class_Plan_Classes",
          icon: "list",
          admin: false,
          order: 51,
        },
        {
          name: "Alunos",
          value: "class_plan_student",
          destination: "genericModule/Class_Plan_Students",
          icon: "list",
          admin: false,
          order: 52,
        },
        {
          name: "Áreas Projeto Educativo",
          value: "class_plan_edu_plan_area",
          destination: "genericModule/Class_Plan_Education_Plan_Areas",
          icon: "list",
          admin: false,
          order: 53,
        },
        {
          name: "Projeto Educativo (Agrupamento)",
          value: "class_plan_edu_plan_cluster",
          destination: "genericModule/Class_Plan_Education_Plan_Clusters",
          icon: "list",
          admin: false,
          order: 54,
        },
        {
          name: "Projeto Educativo (Escola)",
          value: "class_plan_edu_plan_school",
          destination: "genericModule/Class_Plan_Education_Plan_Schools",
          icon: "list",
          admin: false,
          order: 55,
        },
        {
          name: "Grelha de Critérios de Avaliação",
          value: "class_plan_evaluation_criteria",
          destination: "genericModule/Class_Plan_Evaluation_Crits",
          icon: "list",
          admin: false,
          order: 56,
        },
        {
          name: "Instrumentos de Avaliação",
          value: "class_plan_evaluation_instrument",
          destination: "genericModule/Class_Plan_Evaluation_Instruments",
          icon: "list",
          admin: false,
          order: 57,
        },
        {
          name: "Avaliação",
          value: "class_plan_school_record",
          destination: "genericModule/Class_Plan_School_Records",
          icon: "list",
          admin: false,
          order: 58,
        },
        {
          name: "Caracterização da Turma",
          value: "class_plan_class_description",
          destination: "genericModule/Class_Plan_Class_Descriptions",
          icon: "list",
          admin: false,
          order: 59,
        },
        {
          name: "Indisciplina",
          value: "class_plan_indiscipline",
          destination: "genericModule/Class_Plan_Indisciplines",
          icon: "list",
          admin: false,
          order: 60,
        },
        /* {
          name: "Perfil do Aluno",
          value: "class_plan_student_profile",
          destination: 'genericModule/Class_Plan_Student_Profiles',
          icon: 'list',
          admin: false,
          order: 51
        }, */
        {
          name: "Grelha de Medidas de Suporte",
          value: "class_plan_action_plan",
          destination: "genericModule/Class_Plan_Action_Plans",
          icon: "list",
          admin: false,
          order: 61,
        },
        {
          name: "AFC Articulação Horizontal",
          value: "class_plan_flexEdu_horizontal_articulation",
          destination: "genericModule/Class_Plan_FlexEdu_Horizontal_Articulations",
          icon: "list",
          admin: false,
          order: 62,
        },
        {
          name: "AFC Articulação Vertical",
          value: "class_plan_flexEdu_vertical_articulation",
          destination: "genericModule/Class_Plan_FlexEdu_Vertical_Articulations",
          icon: "list",
          admin: false,
          order: 63,
        },
        {
          name: "Documentos",
          value: "myDocuments",
          destination: "myDocuments",
          icon: "list",
          admin: false,
          order: 98,
        },
        {
          name: "Os Meus Ficheiros",
          value: "myFiles",
          destination: "myFiles",
          icon: "list",
          admin: false,
          order: 99,
        },
      ],
      $http: $http,
    };

    return o;
  },
]);

app.directive("ngAnotherstepGrid", function () {
  return {
    templateUrl: "./templates/anotherstep-grid.html",
  };
});

app.config([
  "$stateProvider",
  "$urlRouterProvider",
  "$mdThemingProvider",
  "$httpProvider",
  function ($stateProvider, $urlRouterProvider, $mdThemingProvider, $httpProvider) {
    $httpProvider.interceptors.push("httpAuthInterceptor");

    $stateProvider.state("home", {
      url: "/home",
      templateUrl: "templates/home.html",
      controller: "HomeCtrl",
    });

    $stateProvider.state("login", {
      url: "/login",
      templateUrl: "templates/login.html",
      controller: "LoginCtrl",
    });

    $stateProvider.state("report", {
      url: "/report",
      templateUrl: "templates/report.html",
      controller: "ReportCtrl",
    });

    $stateProvider.state("products", {
      url: "/products",
      templateUrl: "templates/products.html",
      controller: "ProductsCtrl",
    });

    $stateProvider.state("categories", {
      url: "/categories",
      templateUrl: "templates/categories.html",
      controller: "CategoriesCtrl",
    });

    $stateProvider.state("organizations", {
      url: "/organizations",
      templateUrl: "templates/organizations.html",
      controller: "OrganizationsCtrl",
    });

    $stateProvider.state("reports", {
      url: "/reports",
      templateUrl: "templates/reports.html",
      controller: "ReportsCtrl",
    });

    $stateProvider.state("modules", {
      url: "/modules",
      templateUrl: "templates/modules.html",
      controller: "ModulesCtrl",
    });

    $stateProvider.state("permissions", {
      url: "/permissions",
      templateUrl: "templates/permissions.html",
      controller: "PermissionsCtrl",
    });

    $stateProvider.state("client", {
      url: "/client",
      templateUrl: "templates/client.html",
      controller: "ClientCtrl",
    });

    $stateProvider.state("recoverPassword", {
      url: "/recoverPassword",
      templateUrl: "templates/recoverPassword.html",
      controller: "RecoverPassCtrl",
    });

    $stateProvider.state("myDocuments", {
      url: "/myDocuments",
      templateUrl: "templates/myDocuments.html",
      controller: "MyDocumentsCtrl",
    });

    $stateProvider.state("logs", {
      url: "/logs",
      templateUrl: "templates/logs.html",
      controller: "LogsCtrl",
    });

    $stateProvider.state("genericModule", {
      url: "/genericModule/:moduleCollection/:moduleValue",
      templateUrl: "templates/genericModule.html",
      controller: "GenericModuleCtrl",
    });

    $stateProvider.state("genericCategoryView", {
      url: "/genericCategoryView/:catID/:menuMode",
      templateUrl: "templates/genericCategoryView.html",
      controller: "GenericCategoryViewCtrl",
    });

    $stateProvider.state("myFiles", {
      url: "/myFiles",
      templateUrl: "templates/myFiles.html",
      controller: "MyFilesCtrl",
    });

    /* $stateProvider.state('transports', {
      url: '/transports',
      templateUrl: 'templates/transports.html',
      controller: 'TransportsCtrl'
    }); */

    $urlRouterProvider.otherwise("login");
  },
]);

app.factory("exportUiGridService", exportUiGridService);

exportUiGridService.inject = ["uiGridExporterService"];

function exportUiGridService(uiGridExporterService) {
  var service = {
    exportToExcel: exportToExcel,
    uiGridExporterService: uiGridExporterService,
  };

  return service;

  function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
  }

  function exportToExcel(sheetName, gridApi, rowTypes, colTypes, customFileName) {
    var columns = gridApi.grid.options.showHeader ? uiGridExporterService.getColumnHeaders(gridApi.grid, colTypes) : [];
    var data = uiGridExporterService.getData(gridApi.grid, rowTypes, colTypes);
    var fileName = customFileName ? customFileName : "Registos";
    fileName += ".xlsx";
    var wb = new Workbook(),
      ws = sheetFromArrayUiGrid(data, columns);
    wb.SheetNames.push(sheetName);
    wb.Sheets[sheetName] = ws;
    var wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    saveAs(
      new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      }),
      fileName
    );
  }

  function sheetFromArrayUiGrid(data, columns) {
    var ws = {};
    var range = {
      s: {
        c: 10000000,
        r: 10000000,
      },
      e: {
        c: 0,
        r: 0,
      },
    };
    var C = 0;
    columns.forEach(function (c) {
      var v = c.displayName || c.value || columns[i].name;
      addCell(range, v, 0, C, ws);
      C++;
    }, this);
    var R = 1;
    data.forEach(function (ds) {
      C = 0;
      ds.forEach(function (d) {
        var v = d.value;
        // console.log(columns[C].name);
        v = parseValue(v);
        addCell(range, v, R, C, ws);
        C++;
      });
      R++;
    }, this);
    if (range.s.c < 10000000) ws["!ref"] = XLSX.utils.encode_range(range);
    return ws;
  }
  /**
   *
   * @param {*} data
   * @param {*} columns
   */

  function datenum(v, date1904) {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
  }

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  function addCell(range, value, row, col, ws) {
    if (range.s.r > row) range.s.r = row;
    if (range.s.c > col) range.s.c = col;
    if (range.e.r < row) range.e.r = row;
    if (range.e.c < col) range.e.c = col;
    var cell = {
      v: value,
    };
    if (cell.v == null) cell.v = "";
    var cell_ref = XLSX.utils.encode_cell({
      c: col,
      r: row,
    });

    if (typeof cell.v === "number") cell.t = "n";
    else if (typeof cell.v === "boolean") cell.t = "b";
    else if (cell.v instanceof Date) {
      cell.t = "n";
      cell.z = XLSX.SSF._table[14];
      cell.v = datenum(cell.v);
    } else cell.t = "s";

    ws[cell_ref] = cell;
  }

  function parseValue(value) {
    if (String(value).match(/total: /g)) {
      return value.substring(7);
    }
    if (value === "null" || value === "undefined" || value === null) {
      return "";
    }

    return value;
  }
}

app.filter("unique", function () {
  return function (collection, keyname) {
    var output = [],
      keys = [];

    angular.forEach(collection, function (item) {
      if (item[keyname] !== null && item[keyname] !== undefined) {
        var key = item[keyname].toString();
        if (keys.indexOf(key) === -1 && key != null && key != undefined && key != "") {
          keys.push(key);
          output.push(item);
        }
      }
    });
    output.sort(function (a, b) {
      if (a[keyname] < b[keyname]) {
        return -1;
      } else {
        return 1;
      }
    });
    return output;
  };
});

app.filter("uniqueList", function () {
  return function (collection) {
    var output = [];
    angular.forEach(collection, function (item) {
      if (output.indexOf(item) === -1) {
        output.push(item);
      }
    });
    return output;
  };
});

app.filter("sliceArr", function () {
  return function (arr, flag) {
    if (flag == "firstHalf") {
      return (arr || []).slice(0, getIndexOfHalfOfArray(arr));
    } else if (flag == "secondHalf") {
      return (arr || []).slice(getIndexOfHalfOfArray(arr));
    }
  };
});

app.directive("fileread", [
  function () {
    return {
      scope: {
        opts: "=",
      },
      link: function ($scope, $elm, $attrs) {
        $elm.on("change", function (changeEvent) {
          var reader = new FileReader();
          console.log($attrs);
          reader.onload = function (evt) {
            $scope.$apply(function () {
              var data = evt.target.result;

              var workbook = XLSX.read(data, {
                type: "binary",
              });

              //var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
              var headerNames = Object.keys($scope.$parent.activeTab.fields);

              var data = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                header: headerNames,
              });

              /* $scope.opts.columnDefs = [];
            headerNames.forEach(function (h) {
              $scope.opts.columnDefs.push({ field: h, width: "150" });
            }); */

              data.forEach(function (element) {
                if (element != null) {
                  for (const key in $scope.$parent.activeTab.fields) {
                    if ($scope.$parent.activeTab.fields.hasOwnProperty(key)) {
                      const field = $scope.$parent.activeTab.fields[key];
                      if (field.type == "number" || field.type == "currency.euro") {
                        element[key] = parseFloat(element[key]);
                      }
                    }
                  }
                }
              });

              $scope.opts.data = data;

              $elm.val(null);
            });
          };

          reader.readAsBinaryString(changeEvent.target.files[0]);
        });
      },
    };
  },
]);

app.directive("datepickerValidation", function () {
  return {
    require: "ng-model",
    link: function (scope, elm, attrs, ctrl) {
      ctrl.$validators.datepickerValidation = function (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        if (viewValue == null || Object.prototype.toString.call(viewValue) === "[object Date]") {
          // it is valid
          return true;
        }

        // it is invalid
        return false;
      };
    },
  };
});

app.filter("localeOrderBy", [
  function () {
    return function (array, sortPredicate, reverseOrder) {
      if (!Array.isArray(array)) return array;
      if (!sortPredicate) return array;

      var isString = function (value) {
        return typeof value === "string";
      };

      var isNumber = function (value) {
        return typeof value === "number";
      };

      var isBoolean = function (value) {
        return typeof value === "boolean";
      };

      var arrayCopy = [];
      angular.forEach(array, function (item) {
        arrayCopy.push(item);
      });

      arrayCopy.sort(function (a, b) {
        var valueA = a;
        var valueB = b;

        if (isString(valueA))
          return !reverseOrder ? valueA.localeCompare(valueB, "pt") : valueB.localeCompare(valueA, "pt");

        if (isNumber(valueA) || isBoolean(valueA)) return !reverseOrder ? valueA - valueB : valueB - valueA;

        return 0;
      });

      return arrayCopy;
    };
  },
]);

/* app.factory('utilsService', function () {
  return {
    test: function (scope, selected, data) {
      if (selected.name != null && selected.name != "") {
        scope.parentScope.$parent.showLoader();
        let stud = scope
          .getFromTableData("Class_Plan_Students")
          .filter((s) => s.id == selected.name)[0];
        if (stud != null) {
          if (stud.nr_processo != null) {
            selected.aluno = stud.nr_processo;
          } else if (stud.aluno != null) {
            selected.aluno = stud.aluno;
          }
          if (stud.nif != null) {
            selected.nif = stud.nif;
          }
          if (stud.sexo != null) {
            selected.gender = stud.sexo;
          }
        }
        scope.parentScope.$parent.hideLoader();
      }
    }
  };
}); */
