import { react2angular } from "react2angular";
import KSTKAnalyticsLogin from "../react/KSTKAnalyticsLogin";
import KSTKAnalyticsClient from "../react/KSTKAnalyticsClient";
import { KSTKAnalyticsHomepage } from "../react/KSTKAnalyticsHomepage";
import { KSTKAnalyticsSmartContents } from "../react/KSTKAnalyticsSmartContents";
import KSTKAnalyticsHeader from "../react/KSTKAnalyticsHeader";
import KSTKAnalyticsSideNav from "../react/KSTKAnalyticsSideNav";
import { KSTKAnalyticsCategoryView } from "../react/KSTKAnalyticsCategoryView";
import { KSTKAnalyticsFooter } from "../react/KSTKAnalyticsFooter";

var app = angular.module("analytics");

app.component(
  "kstkLogin",
  react2angular(KSTKAnalyticsLogin, [
    "authenticate",
    "resetPassword",
    "changePage",
    "changeLanguage",
    "translateText",
    "getLanguage",
    "getShowLoginCaptcha",
  ])
);
app.component("kstkClient", react2angular(KSTKAnalyticsClient, ["currentUser", "updateUser", "translateText"]));
app.component(
  "kstkHomepage",
  react2angular(
    KSTKAnalyticsHomepage,
    [
      "user",
      "userPreferences",
      "catModuleReportClick",
      "getFavoriteStatus",
      "addRemoveFavorites",
      "smartContents",
      "closeSystemAlert",
      "translateText",
      "getLanguage",
    ],
    ["reportFactory"]
  )
);
app.component(
  "kstkSmartContents",
  react2angular(
    KSTKAnalyticsSmartContents,
    [
      "smartContents",
      "atHomepage",
      "openSmartContents",
      "openCloseSmartContents",
      "smartContentsMode",
      "downloadSmartContentsAttachedFiles",
      "translateText",
    ],
    []
  )
);
app.component(
  "kstkHeader",
  react2angular(
    KSTKAnalyticsHeader,
    [
      "breadCrumb",
      "user",
      "settings",
      "changePage",
      "currentModuleReport",
      "getFavoriteStatus",
      "addRemoveFavorites",
      "openCloseSmartContents",
      "smartContents",
      "smartContentsMode",
      "logout",
      "translateText",
    ],
    ["clientsFactory"]
  )
);
app.component("kstkSideNav", react2angular(KSTKAnalyticsSideNav, ["org", "menu", "catModuleReportClick"]));
app.component(
  "kstkCategoryView",
  react2angular(
    KSTKAnalyticsCategoryView,
    ["menuMode", "menu", "selectedCategory", "catModuleReportClick", "getFavoriteStatus", "addRemoveFavorites"],
    ["reportFactory"]
  )
);
app.component("kstkFooter", react2angular(KSTKAnalyticsFooter, ["org", "changeLanguage", "translateText"]));
