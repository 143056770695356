"use strict";

var app = angular.module("analytics");

app.controller("UpdateClusterChargesPanelCtrl", [
  "$scope",
  "$mdDialog",
  "genericScope",
  "genericFactory",
  function ($scope, $mdDialog, genericScope, genericFactory) {
    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.updateConfig = {};

    $scope.updateExpenses = function () {
      if (Array.isArray($scope.updateConfig["civilYear"]) && $scope.updateConfig["civilYear"].length == 0) {
        return null;
      }
      genericScope.parentScope.$parent.showLoader();

      // Get the months to update

      let monthsToUpdate = [];

      for (let y = 0; y < $scope.updateConfig.civilYear.length; y++) {
        let civilYear = $scope.updateConfig.civilYear[y];
        for (let m = 1; m < 13; m++) {
          let refDateMs = new Date(civilYear + "-" + m + "-01").getTime();
          monthsToUpdate.push({
            year: m < 9 ? (Number(civilYear) - 1).toString() : civilYear,
            civilYear: civilYear,
            month: getMonthFromDateMs(refDateMs),
            date: refDateMs,
          });
        }
      }

      // Get the school to update

      let schoolToUpdate = genericScope.getFromTableData("Transp_CMS_Schos").filter((el) => el.ratioSchool == "Sim");

      // Get base rows to insert

      let rowsToInsertUpdate = [];
      let nowMs = new Date().getTime();
      schoolToUpdate.forEach((school) => {
        monthsToUpdate.forEach((month) => {
          let obj = {};
          obj.organization = school.organization;
          obj.year = month.year;
          obj.civilYear = month.civilYear;
          obj.supplyMonth = month.month;
          obj.date = month.date;
          obj.cluster = school.cluster;
          obj.school = school.id;
          obj.educationLevel = school.educationLevel;
          obj.parsedEducationLevel = genericScope.parseValue(
            school.educationLevel,
            "educationLevel",
            "Transp_Education_Levels"
          );
          // Code IGEFE
          if (school.educationLevel != null) {
            let filteredEduLevel = genericScope
              .getFromTableData("Transp_Education_Levels")
              .filter((l) => l.id === school.educationLevel[0]);
            if (filteredEduLevel.length > 0) {
              obj.codeIGEFE = filteredEduLevel[0].codeIGEFE;
            }
          }
          // Rubricas
          obj.supplyInvoiceDateMs = month.date;
          obj.documentType = "Fatura";
          rowsToInsertUpdate.push(obj);
        });
      });

      if ($scope.updateConfig.expenseType == "Leite Escolar") {
        for (let l = 0; l < rowsToInsertUpdate.length; ) {
          let row = rowsToInsertUpdate[l];
          if (row.civilYear != null && row.school != null) {
            let schoolMilkValue = getCMSSchoolMonthSupplyCost(
              row,
              genericScope.getFromTableData("School_Milk_CMS_Provisions")
            );

            if (schoolMilkValue != null && !isNaN(Number(schoolMilkValue)) && schoolMilkValue > 0) {
              row.value = schoolMilkValue;
              row.schoolMilkValue = schoolMilkValue;
              // Rubrica Apoios Alimentares
              row.chargeType = "6495c47e9de1a0009670b0de";
              // Sub-rubrica Leite Escolar
              row.chargeSubType = "6495c4b89de1a0009670b0e9";
              l++;
            } else {
              rowsToInsertUpdate.splice(l, 1);
            }
          }
        }
      } else if ($scope.updateConfig.expenseType == "PND") {
        if ($scope.updateConfig.nonTeachStaffExpenseType == "Despesa") {
          // PRE
          for (let l = 0; l < rowsToInsertUpdate.length; ) {
            let row = rowsToInsertUpdate[l];
            let rowEdited = false;
            if (row.codeIGEFE == "190") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "prePrimary",
                  "costs",
                  "costsTotal",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffPrePrimary = value;
                  // Rubrica Pessoal Não Docente
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica Pré-Escolar
                  row.chargeSubType = "64a82a052bbdd5007cf28d14";
                  rowEdited = true;
                }
              }
            }
            // 1º Ciclo
            else if (row.codeIGEFE == "191") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "primary",
                  "costs",
                  "costsTotal",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffPrimary = value;
                  // Rubrica Pessoal Não Docente
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 1º Ciclo
                  row.chargeSubType = "64a82a902bbdd5007cf28d18";
                  rowEdited = true;
                }
              }
            }
            // 2º 3º Ciclo
            else if (row.codeIGEFE == "192") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "secondThirdSecondary",
                  "costs",
                  "costsTotal",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffSecondThirdSecondary = value;
                  // Rubrica Pessoal Não Docente
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 2º 3º Ciclo
                  row.chargeSubType = "64a82aa02bbdd5007cf28d19";
                  rowEdited = true;
                }
              }
            }
            if (rowEdited) {
              l++;
            } else {
              rowsToInsertUpdate.splice(l, 1);
            }
          }
        } else if ($scope.updateConfig.nonTeachStaffExpenseType == "Seguro AT") {
          // PRE
          for (let l = 0; l < rowsToInsertUpdate.length; ) {
            let row = rowsToInsertUpdate[l];
            let rowEdited = false;
            if (row.codeIGEFE == "190") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "prePrimary",
                  "insuranceCosts",
                  "insuranceCharge",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffPrePrimaryInsurances = value;
                  // Rubrica Seguros AT
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica Pré-Escolar
                  row.chargeSubType = "64a82aa82bbdd5007cf28d1a";
                  rowEdited = true;
                }
              }
            }
            // 1º Ciclo
            else if (row.codeIGEFE == "191") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "primary",
                  "insuranceCosts",
                  "insuranceCharge",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffPrimaryInsurances = value;
                  // Rubrica Seguros AT
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 1º Ciclo
                  row.chargeSubType = "64a82aa82bbdd5007cf28d1a";
                  rowEdited = true;
                }
              }
            }
            // 2º 3º Ciclo
            else if (row.codeIGEFE == "192") {
              if (row.civilYear != null && row.supplyInvoiceDateMs != null && row.school != null) {
                let value = getCMSNonTeachingStaffMonthExpenses(
                  row,
                  genericScope.getFromTableData("Non_Teach_Staff_Descriptions"),
                  "secondThirdSecondary",
                  "insuranceCosts",
                  "insuranceCharge",
                  genericScope
                );
                if (value != null && !isNaN(Number(value)) && value > 0) {
                  row.value = value;
                  row.nonTeachingStaffSecondThirdSecondaryInsurances = value;
                  // Rubrica Seguros AT
                  row.chargeType = "64a829ba2bbdd5007cf28d11";
                  // Sub-rubrica 2º 3º Ciclo
                  row.chargeSubType = "64a82aa82bbdd5007cf28d1a";
                  rowEdited = true;
                }
              }
            }
            if (rowEdited) {
              l++;
            } else {
              rowsToInsertUpdate.splice(l, 1);
            }
          }
        }
      }

      // Check for duplicates
      let rowsToUpdate = [];

      for (let m = 0; m < rowsToInsertUpdate.length; ) {
        let row = rowsToInsertUpdate[m];
        let filteredDataRow = genericScope.gridOptions.data.filter(
          (el) =>
            el.civilYear == row.civilYear &&
            el.school != null &&
            el.school == row.school &&
            el.supplyMonth == row.supplyMonth &&
            el.chargeSubType == row.chargeSubType
        );
        if (filteredDataRow != null && Array.isArray(filteredDataRow) && filteredDataRow.length > 0) {
          filteredDataRow = filteredDataRow[0];
          // Only update rows that have different values
          if (row.value != filteredDataRow.value) {
            filteredDataRow.value = row.value;
            rowsToUpdate.push(filteredDataRow);
          }
          rowsToInsertUpdate.splice(m, 1);
        } else {
          m++;
        }
      }

      // Concat rows to insert with rows to update
      rowsToInsertUpdate = rowsToInsertUpdate.concat(rowsToUpdate);

      if (rowsToInsertUpdate.length > 0) {
        let numInsertedUpdatedRows = 0;
        async.eachSeries(
          rowsToInsertUpdate,
          function iteratee(item, callback) {
            if (item.id != null) {
              item.modifiedBy = "admin";
              item.modifiedAt = nowMs;
              genericFactory.modify(item.id, item).then((el) => {
                numInsertedUpdatedRows += 1;
                callback();
              });
            } else {
              item.createdBy = "admin";
              item.createdAt = nowMs;
              genericFactory.create(item).then((el) => {
                numInsertedUpdatedRows += 1;
                callback();
              });
            }
          },
          function done() {
            if (numInsertedUpdatedRows == rowsToInsertUpdate.length) {
              genericScope.closePanel();
              genericScope.parentScope.alertUpdatedClusterCharges(
                numInsertedUpdatedRows,
                $scope.updateConfig.expenseType
              );
              genericScope.parentScope.$parent.hideLoader();
              genericScope.getModuleData();
            }
          },
          function (err) {
            if (err) {
              console.log(err);
              genericScope.closePanel();
              genericScope.parentScope.$parent.hideLoader();
              genericScope.getModuleData();
            } else {
              //Done
              console.log("done");
              genericScope.parentScope.$parent.hideLoader();
              genericScope.getModuleData();
            }
          }
        );
      } else {
        genericScope.parentScope.alertNoClusterChargesToUpdate();
        genericScope.parentScope.$parent.hideLoader();
      }
    };
  },
]);
