"use strict";

var app = angular.module("analytics");

("use strict");

var app = angular.module("analytics");

app.controller("UploadFilePanelCtrl", [
  "$scope",
  "genericFactory",
  "clientsFactory",
  "genericScope",
  "data",
  "importerArgs",
  "$timeout",
  "uiGridGroupingConstants",
  function (
    $scope,
    genericFactory,
    clientsFactory,
    genericScope,
    data,
    importerArgs,
    $timeout,
    uiGridGroupingConstants
  ) {
    var parentScope = genericFactory.getParentScope();
    $scope.genericScope = genericScope;
    $scope.data = data;
    $scope.gridApi;
    $scope.selectedFile;
    $scope.importerFields = {};
    $scope.uiGridGroupingConstants = uiGridGroupingConstants;

    if (importerArgs != null) {
      $scope.importerArgs = importerArgs;
    } else {
      $scope.importerArgs = $scope.genericScope.activeTab;
    }

    $scope.fileTypes = ["Excel", "CSV"];

    $scope.gridOptions = {
      enableHorizontalScrollbar: 0,
      enableHorizontalScrollbar: true,
      enableVerticalScrollbar: true,
      enableColumnResizing: true,
      enableFiltering: true,
      showColumnFooter: true,
      onRegisterApi: function (gridApi) {
        $scope.gridApi = gridApi;

        setInterval(
          () => {
            $scope.gridApi.core.handleWindowResize();
          },
          500,
          10
        );

        /* $scope.gridOptions.data = $scope.data;

        for (var i = 0; i < $scope.gridOptions.columnDefs.length; i++) {
          $scope.gridOptions.columnDefs[i].width = '*';
        } */
      },
    };

    $scope.testFile = function () {
      parentScope.$parent.showLoader();
      let file = document.getElementById("file").files[0];
      var reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onloadend = function () {
        var workbook = XLSX.read(reader.result, {
          type: "binary",
        });
        $scope.headerNames = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          header: 1,
        })[0];

        var fileData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

        let importerCustomFunctions = $scope.importerArgs.importerCustomFunctions;
        parentScope.$parent.hideLoader();
        if (importerCustomFunctions != null) {
          for (let i = 0; i < importerCustomFunctions.length; i++) {
            let functionName = importerCustomFunctions[i];
            window[functionName]($scope, $scope.headerNames, fileData);
          }
        }

        if (fileData && fileData.length > 0) {
          // If importer is enable, check if columnDefs and headerNames have the same length
          if ($scope.genericScope.activeTab.importerPreviewTable) {
            let validImportedFileFlag = true;

            for (const activeTabField in $scope.genericScope.activeTab.fields) {
              let activeTabFieldInfo = $scope.genericScope.activeTab.fields[activeTabField];
              if ($scope.headerNames.indexOf(activeTabFieldInfo.label + "") == -1) {
                validImportedFileFlag = false;
                break;
              }
            }
            if (!validImportedFileFlag) {
              $scope.selectedFile = null;
              document.getElementById("file").value = "";
              parentScope.$parent.ptPreviewPanelInvalidImportFile(
                Object.values($scope.genericScope.activeTab.fields).map((x) => (x = x.label))
              );
              return;
            }
          }
          for (let j = 0; j < $scope.headerNames.length; j++) {
            let element = $scope.headerNames[j];
            for (const key in $scope.genericScope.activeTab.fields) {
              if ($scope.genericScope.activeTab.fields.hasOwnProperty(key)) {
                const field = $scope.genericScope.activeTab.fields[key];
                if (field.label == element) {
                  let newColumn;
                  if (field.viewType == "date") {
                    newColumn = {
                      name: element,
                      width: "150",
                      type: "string",
                      field: key,
                      cellTemplate:
                        '<div class="ui-grid-cell-contents" >{{grid.appScope.msToDate(grid.getCellValue(row, col))}}</div>',
                      filter: {
                        condition: function (searchTerm, cellValue, row, column) {
                          var re = new RegExp(searchTerm, "gi");
                          return $scope.msToDate(cellValue).match(re);
                        },
                      },
                    };
                  } else {
                    newColumn = {
                      name: element,
                      width: "150",
                      type: field.type,
                      field: key,
                    };
                    if (field.aggregationFunction) {
                      parseAggregationFunction(newColumn, field.aggregationFunction);
                    }
                  }
                  if (field.sort) {
                    newColumn["sort"] = {
                      priority: field.sort.priority,
                      direction: field.sort.direction,
                    };
                  }
                  $scope.gridOptions.columnDefs.push(newColumn);
                }
              }
            }
          }

          function parseAggregationFunction(column, func) {
            switch (func) {
              case "SUM":
                column.treeAggregationType = uiGridGroupingConstants.aggregation.SUM;
                column.customTreeAggregationFinalizerFn = function (aggregation) {
                  aggregation.rendered = Math.round(aggregation.value * 100) / 100;
                };
                break;
              default:
                break;
            }
          }

          let fieldsWithoutData = [];
          for (const key in $scope.genericScope.activeTab.fields) {
            if ($scope.genericScope.activeTab.fields.hasOwnProperty(key)) {
              let field = $scope.genericScope.activeTab.fields[key];
              if (field.hiddenInTable == null && field.ignoreOnUpload == null) {
                fieldsWithoutData.push(field.label);
              }
            }
          }

          fileData.forEach(function (element) {
            if (element != null) {
              for (let h = 0; h < $scope.headerNames.length; h++) {
                let header = $scope.headerNames[h];
                for (const key in $scope.genericScope.activeTab.fields) {
                  if ($scope.genericScope.activeTab.fields.hasOwnProperty(key)) {
                    const field = $scope.genericScope.activeTab.fields[key];
                    if (field.label == header) {
                      if (
                        fieldsWithoutData.indexOf(field.label) != -1 &&
                        element[field.label] != null &&
                        element[field.label] != ""
                      ) {
                        fieldsWithoutData.splice(fieldsWithoutData.indexOf(field.label), 1);
                      }
                      element[key] = element[field.label];
                      delete element[field.label];
                    }
                  }
                }
              }
            }
          });

          if (
            !$scope.genericScope.activeTab.importerPreviewTable &&
            fieldsWithoutData &&
            Array.isArray(fieldsWithoutData) &&
            fieldsWithoutData.length > 0 &&
            $scope.importerArgs.importerUpdateRowsKeyFields == null
          ) {
            parentScope.$parent.alertFieldsWithoutData(fieldsWithoutData);
          }

          $scope.gridOptions.data = fileData;
          refresh();
        } else {
          parentScope.$parent.alertNoRowsToImport();
        }
      };
      console.log($scope.gridOptions.columnDefs);
      //parentScope.closePanel();
    };

    $scope.upload = function () {
      //PREVIEW TABLE UPDATE ROWS
      if ($scope.genericScope.activeTab.importerPreviewTable) {
        parentScope.$parent.showLoader();
        let originalImporterPreviewTableRowNumber = JSON.parse(JSON.stringify($scope.gridOptions.data)).length;
        for (let j = 0; j < $scope.gridOptions.data.length; ) {
          let toImportEl = $scope.gridOptions.data[j];
          let hasFilledEditableFieldsFlag = false;
          for (let k = 0; k < $scope.genericScope.activeTab.editableFields.length; k++) {
            let editableField = $scope.genericScope.activeTab.editableFields[k];
            if (
              toImportEl[editableField.name] != null &&
              toImportEl[editableField.name] != "" &&
              JSON.stringify(toImportEl[editableField.name]).length < 50
            ) {
              let validCellValueFlag = false;
              if (editableField.type == "number" && !isNaN(Number(toImportEl[editableField.name]))) {
                toImportEl[editableField.name] = Number(toImportEl[editableField.name]);
                validCellValueFlag = true;
              } else if (editableField.type == "string") {
                if (
                  $scope.genericScope.selected.qualitativeLevels == null ||
                  ($scope.genericScope.selected.qualitativeLevels &&
                    $scope.genericScope.selected.qualitativeLevels[toImportEl.parsedEssentialLearning] &&
                    $scope.genericScope.selected.qualitativeLevels[toImportEl.parsedEssentialLearning].indexOf(
                      toImportEl.evaluationQualitativeLevel
                    ) != -1)
                ) {
                  validCellValueFlag = true;
                } else {
                  validCellValueFlag = false;
                }
              } else if (editableField.type != "number" && editableField.type != "string") {
                if (!isNaN(Number(toImportEl[editableField.name]))) {
                  toImportEl[editableField.name] = Number(toImportEl[editableField.name]);
                  validCellValueFlag = true;
                } else {
                  validCellValueFlag = true;
                }
              }
              if (validCellValueFlag && toImportEl.rowID != null && toImportEl.rowID != "") {
                hasFilledEditableFieldsFlag = true;
                break;
              }
            }
          }
          if (!hasFilledEditableFieldsFlag) {
            $scope.gridOptions.data.splice(j, 1);
          } else {
            j++;
          }
        }
        let updatedRows = 0;
        if ($scope.gridOptions.data.length > 0) {
          $timeout(function () {
            uploadRows();
          }, 2000);
          function uploadRows() {
            $scope.gridOptions.data.forEach((uploadTabelEl) => {
              $scope.genericScope.gridOptions.data.forEach((previewTabelEl) => {
                if (uploadTabelEl.rowID == previewTabelEl.rowID) {
                  for (let k = 0; k < $scope.genericScope.activeTab.editableFields.length; k++) {
                    let editableField = $scope.genericScope.activeTab.editableFields[k];
                    $scope.genericScope.gridApi.edit.raise.beginCellEdit(previewTabelEl, editableField.name);
                    let newValue =
                      uploadTabelEl[editableField.name] != null
                        ? JSON.parse(JSON.stringify(uploadTabelEl[editableField.name]))
                        : null;
                    let oldValue =
                      previewTabelEl[editableField.name] != null
                        ? JSON.parse(JSON.stringify(previewTabelEl[editableField.name]))
                        : null;
                    if (editableField.type == "number") {
                      if (!isNaN(Number(newValue))) {
                        newValue = newValue != null ? Number(newValue) : null;
                      }
                      if (!isNaN(Number(oldValue))) {
                        oldValue = oldValue != null ? Number(oldValue) : null;
                      }
                    }
                    let checkedNewValue = newValue;
                    // Match new value according to editable field dropdown values
                    if (
                      editableField.editDropdownOptionsArray != null &&
                      Array.isArray(editableField.editDropdownOptionsArray) &&
                      editableField.editDropdownOptionsArray.length > 0
                    ) {
                      let validNewValueFlag = false;
                      for (let w = 0; w < editableField.editDropdownOptionsArray.length; w++) {
                        let dropdownOption = editableField.editDropdownOptionsArray[w];
                        if (newValue == dropdownOption.value) {
                          validNewValueFlag = true;
                          break;
                        }
                      }
                      if (!validNewValueFlag) {
                        checkedNewValue = oldValue;
                      }
                    }
                    previewTabelEl[editableField.name] = checkedNewValue;
                    $scope.genericScope.gridApi.edit.raise.afterCellEdit(
                      previewTabelEl,
                      editableField,
                      newValue,
                      oldValue
                    );
                  }
                  updatedRows += 1;
                }
              });
            });

            if (updatedRows > 0) {
              parentScope.$parent.toastFinishRowsImport(updatedRows, originalImporterPreviewTableRowNumber);
            } else {
              parentScope.$parent.alertNoRowsToImport();
            }
            $scope.genericScope.closePanel();
            parentScope.$parent.hideLoader();
          }
        } else {
          parentScope.$parent.alertNoRowsToImport();
          $scope.genericScope.closePanel();
          parentScope.$parent.hideLoader();
        }
      }
      //UPDATE ROWS
      else if ($scope.importerArgs.importerUpdateRowsKeyFields != null) {
        parentScope.$parent.showLoader();
        let rowsToUpdate = [];
        let updatedRows = 0;
        for (let j = 0; j < $scope.gridOptions.data.length; j++) {
          let toImportEl = $scope.gridOptions.data[j];
          for (let w = 0; w < $scope.data.length; w++) {
            let moduleEl = $scope.data[w];
            let validElFlag = true;
            //Check if key fields match
            for (let i = 0; i < $scope.importerArgs.importerUpdateRowsKeyFields.length; i++) {
              let keyField = $scope.importerArgs.importerUpdateRowsKeyFields[i];
              let parsedModuleEl = $scope.genericScope.parseValue(moduleEl[keyField], keyField);
              if (moduleEl[keyField] == null || moduleEl[keyField] == "") {
                validElFlag = false;
              } else if (parsedModuleEl != null && parsedModuleEl != "" && parsedModuleEl != toImportEl[keyField]) {
                validElFlag = false;
              } else if (
                (parsedModuleEl == null || parsedModuleEl == "") &&
                moduleEl[keyField] != toImportEl[keyField]
              ) {
                validElFlag = false;
              }
            }
            if (!validElFlag) {
              continue;
            }
            //Update module element
            for (let i = 0; i < $scope.importerArgs.importerUpdateRowsFieldsToUpdate.length; i++) {
              let fieldToUpdate = $scope.importerArgs.importerUpdateRowsFieldsToUpdate[i];
              if (toImportEl[fieldToUpdate] != null && toImportEl[fieldToUpdate] != "") {
                if (
                  $scope.genericScope.activeTab.fields[fieldToUpdate].type == "number" &&
                  $scope.genericScope.activeTab.fields[fieldToUpdate].viewType != "date"
                ) {
                  toImportEl[fieldToUpdate] = Number(toImportEl[fieldToUpdate]);
                } else if (
                  $scope.genericScope.activeTab.fields[fieldToUpdate].type == "string" &&
                  $scope.genericScope.activeTab.fields[fieldToUpdate].viewType != "dynamicField"
                ) {
                  toImportEl[fieldToUpdate] = toImportEl[fieldToUpdate] + "";
                } else if ($scope.genericScope.activeTab.fields[fieldToUpdate].viewType == "date") {
                  if (typeof toImportEl[fieldToUpdate] == "string") {
                    let parts = toImportEl[fieldToUpdate].split("/");
                    toImportEl[fieldToUpdate] = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]).getTime();
                  } else {
                    toImportEl[fieldToUpdate] = toImportEl[fieldToUpdate];
                  }
                } else if (
                  $scope.genericScope.activeTab.fields[fieldToUpdate].type == "string" &&
                  $scope.genericScope.activeTab.fields[fieldToUpdate].viewType == "dynamicField"
                ) {
                  toImportEl[fieldToUpdate] = eval(toImportEl[fieldToUpdate]);
                }
                if ($scope.genericScope.activeTab.fields[fieldToUpdate].multiple) {
                  toImportEl[fieldToUpdate] = toImportEl[fieldToUpdate].split(",");
                }
                moduleEl[fieldToUpdate] = toImportEl[fieldToUpdate];
              }
            }
            rowsToUpdate.push(moduleEl);
            break;
          }
        }
        parentScope.$parent.hideLoader();
        parentScope.$parent.showDeterminateLoader(rowsToUpdate.length);
        if (rowsToUpdate.length > 0) {
          async.eachSeries(
            rowsToUpdate,
            function (item, callback) {
              genericFactory.modify(item.id, item).then(() => {
                parentScope.$parent.updateDeterminateLoader();
                updatedRows += 1;
                callback();
              });
            },
            function (err) {
              if (updatedRows > 0) {
                if (err) {
                  console.log(err);
                } else {
                  //Done
                  parentScope.$parent.hideLoader();
                  parentScope.closePanel();
                  parentScope.$parent.toastFinishRowsImport(updatedRows, $scope.gridOptions.data.length);
                }
              }
            }
          );
        } else {
          parentScope.$parent.hideLoader();
          parentScope.closePanel();
          parentScope.$parent.alertNoRowsToImport();
        }
      }
      //UPLOAD ROWS
      else {
        parentScope.$parent.showLoader();
        let toUploadRows = $scope.gridOptions.data.length;
        let uploadedRows = 0;

        let importerCheckDuplicateFields = $scope.importerArgs.importerCheckDuplicateFields;
        if (importerCheckDuplicateFields != null && Array.isArray($scope.data) && $scope.data.length > 0) {
          for (let j = 0; j < $scope.gridOptions.data.length; ) {
            let toImportEl = $scope.gridOptions.data[j];
            let duplicateElFlag = false;
            for (let w = 0; w < $scope.data.length; w++) {
              let moduleEl = $scope.data[w];
              for (let i = 0; i < importerCheckDuplicateFields.length; i++) {
                let field = importerCheckDuplicateFields[i];
                if (
                  toImportEl[field] != null &&
                  moduleEl[field] != null &&
                  toImportEl[field] != "" &&
                  moduleEl[field] != "" &&
                  toImportEl[field] == moduleEl[field]
                ) {
                  duplicateElFlag = true;
                } else {
                  duplicateElFlag = false;
                  break;
                }
              }
              if (duplicateElFlag) {
                $scope.gridOptions.data.splice(j, 1);
                break;
              }
              if (w == $scope.data.length - 1 && !duplicateElFlag) {
                j++;
              }
            }
          }
        }
        parentScope.$parent.hideLoader();
        parentScope.$parent.showDeterminateLoader(toUploadRows);

        if ($scope.gridOptions.data.length > 0) {
          async.eachSeries(
            $scope.gridOptions.data,
            function (item, callback) {
              //Clean row external fields
              for (const field in item) {
                if (item.hasOwnProperty(field)) {
                  const fieldValue = item[field];
                  if (Object.keys($scope.genericScope.activeTab.fields).indexOf(field) == -1) {
                    delete item[field];
                  }
                }
              }

              item["organization"] = clientsFactory.getUser().user.organization;
              item.createdAt = new Date().getTime();
              item.createdBy = $scope.genericScope.currentUser;
              item.uploadedFromFileRowFlag = true;

              genericFactory.create(item).then(() => {
                parentScope.$parent.updateDeterminateLoader();
                uploadedRows += 1;
                callback();
              });
            },
            function (err) {
              if (uploadedRows > 0) {
                if (err) {
                  console.log(err);
                } else {
                  //Done
                  if ($scope.importerArgs.importerUploadFromTableValues) {
                    genericFactory
                      .getByProperty("organization", clientsFactory.getUser().user.organization)
                      .then(function (moduleUpdatedData) {
                        let filteredJustUploadedRows = moduleUpdatedData.filter(
                          (el) => el.uploadedFromFileRowFlag != null && el.uploadedFromFileRowFlag == true
                        );
                        filteredJustUploadedRows.forEach((row) => {
                          row.uploadedFromFileRowFlag = false;
                        });
                        parentScope.$parent.hideDeterminateLoader();
                        parentScope.$parent.showLoader();
                        utilFunctions["mongoMapping"](
                          $scope.genericScope,
                          filteredJustUploadedRows,
                          "default",
                          null,
                          null,
                          true
                        );
                        parentScope.$parent.toastFinishRowsImport(uploadedRows, toUploadRows);
                        /* parentScope.closePanel(); */
                      });
                  } else {
                    parentScope.$parent.hideLoader();
                    parentScope.closePanel();
                    parentScope.$parent.toastFinishRowsImport(uploadedRows, toUploadRows);
                  }
                }
              }
            }
          );
        } else {
          parentScope.$parent.hideLoader();
          parentScope.closePanel();
          parentScope.$parent.alertNoRowsToImport();
        }
      }
    };

    $scope.cleanTable = function () {
      $scope.gridOptions.data = null;
      $scope.gridOptions.columnDefs = null;
      $scope.selectedFile = null;
      document.getElementById("file").value = "";
      refresh();
    };

    var refresh = function () {
      $scope.refresh = true;
      $timeout(function () {
        $scope.refresh = false;
      }, 0);
    };

    $scope.onFileChange = function (event) {
      $scope.selectedFile = event.target.files[0];
    };

    $scope.msToDate = function (num) {
      if (num != "null" && num != "undefined" && num != null) {
        var date = new Date(num).toLocaleDateString("pt-PT");
        if (date === "Invalid Date") {
          return "";
        } else {
          return date;
        }
      } else {
        return "";
      }
    };

    $scope.keys = function (object) {
      return Object.keys(object).length;
    };

    $scope.checkIfEmptyImporterFields = function () {
      if ($scope.importerArgs.importerFields == null) {
        return false;
      } else {
        let filledImporterFields = importerFieldsCheck($scope.importerFields);
        if (filledImporterFields.length == $scope.importerArgs.importerFields.length) {
          return false;
        } else {
          return true;
        }
      }
    };
  },
]);
